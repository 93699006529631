import React, { useState, useEffect, useCallback } from "react";
import {
  Grid,
  TextField,
  Button,
  Card,
  Typography,
  Tabs,
  Tab,
  Box,
  CircularProgress,
  FormControlLabel,
  RadioGroup,
  Radio,
  Modal,
  InputAdornment,
  IconButton,
  FormControl,
  FormHelperText,
  Avatar,
} from "@mui/material";

import SoftBox from "components/SoftBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import axios from "../../api";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useSelector } from "react-redux";
import { Formik, Form } from "formik";
import { useSnackbar } from "components/AlertMessages/SnackbarContext";
import Locations from "examples/ReuseFunctions/Locations";
import AddressComponent from "./AddressComponent";
import AddAddress from "./AddAddress";
import * as Yup from "yup";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import AWS from "aws-sdk";
import { useDropzone } from "react-dropzone";
import { Edit as EditIcon } from "@mui/icons-material";

const customScrollbarStyle = {
  "&::-webkit-scrollbar": {
    width: "8px",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "#44444461",
    borderRadius: "8px",
  },
  "&::-webkit-scrollbar-thumb:hover": {
    backgroundColor: "#666",
  },
};

const s3 = new AWS.S3({
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
  region: process.env.REACT_APP_AWS_REGION,
});

const validationSchema = Yup.object({
  name: Yup.string().required("Name is required"),
  email: Yup.string()
    .matches(
      /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
      "Invalid email format"
    )
    .typeError("Enter a valid email")
    .required("Email is required"),
  phone: Yup.string()
    .matches(/^[0-9]{10}$/, "Enter a valid phone number")
    .required("Phone number is required"),
  type: Yup.string().required("Notification type is required"),
});
const passwordValidationSchema = Yup.object({
  password: Yup.string()
    .required("Current password is required")
    .min(6, "Password must be at least 6 characters")
    .matches(
      /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[!@#$%^&*()_+{}[\]:;<>,.?~\\/-])/,
      "Password must contain at least one letter, one number, and one special character"
    ),
  newPassword: Yup.string()
    .required("New password is required")
    .min(6, "Password must be at least 6 characters")
    .matches(
      /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[!@#$%^&*()_+{}[\]:;<>,.?~\\/-])/,
      "Password must contain at least one letter, one number, and one special character"
    ),
});
const Profile = () => {
  const [userData, setUserData] = useState(null);
  const { fetchError, fetchSuccess } = useSnackbar();

  const user = useSelector((state) => state.auth.user);
  const { states, cities, areas, setSelectedState, setSelectedCity } =
    Locations();
  const [tabIndex, setTabIndex] = useState(0);
  const [loading, setLoading] = useState(true);
  const [addressData, setAddressData] = useState(null);
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [otpModalOpen, setOtpModalOpen] = useState(false);
  const [otp, setOtp] = useState("");
  const [newEmail, setNewEmail] = useState("");
  const [formikProps, setFormikProps] = useState(null);
  const [showPassword, setShowPassword] = React.useState(false);
  const [showNewPassword, setShowNewPassword] = React.useState(false);
  const fetchUserData = useCallback(async () => {
    setLoading(true);
    try {
      const response = await axios.get(`/customer/${user?.userId}`);
      setUserData(response?.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }, [user]);

  const fetchAddressData = useCallback(async () => {
    try {
      const response = await axios.get(`/address/customer/${user?.userId}`);
      const { data } = response;
      setAddressData(data?.list);
    } catch (error) {
      console.error(error.response.data.error);
    }
  }, [user]);

  useEffect(() => {
    fetchUserData();
    fetchAddressData();
  }, [user, fetchUserData, fetchAddressData]);

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  const handleOpen = () => {
    setIsEditOpen(true);
  };

  const handleClose = () => {
    setIsEditOpen(false);
  };

  const handleOpenOtpModal = () => {
    setOtpModalOpen(true);
  };

  const handleCloseOtpModal = () => {
    setOtpModalOpen(false);
  };

  const handleSendOtp = async (email) => {
    handleOpenOtpModal();
    try {
      await axios.post("/otp/send-otp", { email });
    } catch (error) {
      handleCloseOtpModal();
      fetchError(error.response.data.error);
    }
  };

  const handleOtpSubmit = async (values) => {
    try {
      const response = await axios.put(`/customer/${userData.customer_id}`, {
        ...values,
        otp,
      });
      if (response.status === 200) {
        handleCloseOtpModal();
        fetchSuccess("Email updated successfully");
        fetchUserData();
      }
    } catch (error) {
      fetchError(error.response.data.error);
    }
  };
  const handlePrimaryAddress = async (event, addressId, customerId) => {
    await axios.put(`/address/is-primary/${addressId}`, {
      is_primary: event.target.value,
      customer_id: customerId,
    });
    fetchAddressData();
    fetchSuccess("Address selected as primary");
  };

  const [uploadedFiles, setUploadedFiles] = useState([]);
  const dropzone = useDropzone({
    accept: {
      "image/png": [".png", ".jpeg", ".jpg"],
      "video/mp4": [".mp4"],
    },
    onDrop: (acceptedFiles) => {
      // Append new files to the existing uploadedFiles array
      setUploadedFiles((prevFiles) => [
        ...prevFiles,
        ...acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        ),
      ]);
    },
  });

  useEffect(() => {
    setUploadedFiles(dropzone.acceptedFiles);
  }, [dropzone.acceptedFiles]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {loading ? (
        <CircularProgress />
      ) : (
        <Card
          sx={{
            maxWidth: "100vw",
            width: "100%",
            padding: "30px",
            maxHeight: "70%",
          }}
        >
          <SoftBox mt={5} md={12} lg={12}>
            <Grid justifyContent="center">
              <Grid item xs={12} md={8} lg={6}>
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center"
                  marginBottom={2}
                >
                  <Grid item>
                    <Typography variant="h5" component="div" gutterBottom>
                      Profile Information
                    </Typography>
                  </Grid>
                  {tabIndex === 1 && (
                    <Grid item>
                      <Button
                        className="success-btn"
                        type="submit"
                        variant="contained"
                        onClick={handleOpen}
                        color="success"
                      >
                        Add Address
                      </Button>
                    </Grid>
                  )}
                </Grid>
                <Box className="profile-tabs">
                  <Tabs value={tabIndex} onChange={handleTabChange}>
                    <Tab label="Personal Information" />
                    <Tab label="Update Address" />
                    <Tab label="Change Password" />
                  </Tabs>
                  <Box mt={3}>
                    {tabIndex === 0 && (
                      <Formik
                        enableReinitialize
                        initialValues={{
                          name: userData?.name || "",
                          email: userData?.email || "",
                          phone: userData?.phone || "",
                          type: userData?.type || "",
                        }}
                        validationSchema={validationSchema}
                        onSubmit={async (values, actions) => {
                          if (values.email !== userData.email) {
                            setNewEmail(values.email);
                            setFormikProps({ values, actions });
                            handleSendOtp(values.email);
                          } else {
                            try {
                              if (uploadedFiles && uploadedFiles.length > 0) {
                                const file = uploadedFiles[0];
                                const fileName = `${Date.now()}-${file.name}`;
                                values.profile_image = fileName;
                                const params = {
                                  Bucket: process.env.REACT_APP_AWS_BUCKET_NAME,
                                  Key: fileName,
                                  Body: file,
                                  ContentType: file.type,
                                };
                                s3.upload(params, async (err, data) => {
                                  if (err) {
                                    console.error("Error uploading file:", err);
                                  } else {
                                    try {
                                      const response = await axios.put(
                                        `/customer/${userData.customer_id}`,
                                        values
                                      );
                                      fetchSuccess(response.data.message);
                                      fetchUserData();
                                    } catch (error) {
                                      fetchError(error.response.data.error);
                                    }
                                  }
                                });
                              } else {
                                const response = await axios.put(
                                  `/customer/${userData.customer_id}`,
                                  values
                                );
                                fetchSuccess(response.data.message);
                                fetchUserData();
                              }
                            } catch (error) {
                              fetchError(error.response.data.error);
                            }
                          }
                        }}
                      >
                        {({ values, errors, touched, handleChange }) => (
                          <Form>
                            <Grid container spacing={3}>
                              <Grid item xs={12} sm={6}>
                                <TextField
                                  fullWidth
                                  placeholder="Name"
                                  name="name"
                                  value={values.name}
                                  onChange={handleChange}
                                  error={!!errors.name && touched.name}
                                  helperText={touched.name && errors.name}
                                />
                              </Grid>
                              <Grid item xs={12} sm={6}>
                                <TextField
                                  fullWidth
                                  placeholder="Email"
                                  name="email"
                                  value={values.email}
                                  onChange={handleChange}
                                  error={!!errors.email && touched.email}
                                  helperText={touched.email && errors.email}
                                />
                              </Grid>
                              <Grid item xs={12} sm={6}>
                                <TextField
                                  fullWidth
                                  placeholder="Phone Number"
                                  name="phone"
                                  inputProps={{ maxLength: 10 }}
                                  value={values.phone}
                                  onChange={handleChange}
                                  error={!!errors.phone && touched.phone}
                                  helperText={touched.phone && errors.phone}
                                />
                              </Grid>
                              <Grid
                                item
                                xs={12}
                                md={6}
                                sx={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <FormControl
                                  component="fieldset"
                                  error={!!errors.type && touched.type}
                                >
                                  <RadioGroup
                                    aria-label="notification_preference"
                                    name="type"
                                    value={values.type}
                                    onChange={handleChange}
                                    row
                                  >
                                    <FormControlLabel
                                      value="Email"
                                      control={<Radio />}
                                      label="Email"
                                    />
                                    <FormControlLabel
                                      value="SMS"
                                      control={<Radio />}
                                      label="SMS"
                                    />
                                    <FormControlLabel
                                      value="WhatsApp"
                                      control={<Radio />}
                                      label="WhatsApp"
                                    />
                                  </RadioGroup>
                                  {touched.type && errors.type && (
                                    <FormHelperText>
                                      {errors.type}
                                    </FormHelperText>
                                  )}
                                </FormControl>
                              </Grid>
                              <Grid
                                item
                                xs={12}
                                md={6}
                                className="profile-section"
                              >
                                {uploadedFiles.length > 0 &&
                                uploadedFiles[0]?.preview ? (
                                  <Avatar
                                    className="profile-img"
                                    sx={{
                                      width: { xs: 84, lg: 84 },
                                      height: { xs: 84, lg: 84 },
                                      cursor: "pointer",
                                    }}
                                    src={uploadedFiles[0]?.preview}
                                  />
                                ) : (
                                  <Avatar
                                    className="profile-img"
                                    sx={{
                                      width: { xs: 84, lg: 84 },
                                      height: { xs: 84, lg: 84 },
                                      cursor: "pointer",
                                    }}
                                    src={
                                      userData?.profile_image
                                        ? `${process.env.REACT_APP_CLOUD_FRONT_URL}/${userData?.profile_image}`
                                        : null
                                    }
                                  />
                                )}
                                <Box
                                  className="profile-edit"
                                  sx={{
                                    cursor: "pointer",
                                    width: "fit-content",
                                  }}
                                  {...dropzone.getRootProps()}
                                >
                                  <Box>
                                    <label htmlFor="image-upload-input">
                                      <input
                                        name="profile_image"
                                        {...dropzone.getInputProps({
                                          name: "profile_image",
                                        })}
                                      />
                                      <IconButton aria-label="edit">
                                        <EditIcon />
                                      </IconButton>
                                    </label>
                                  </Box>
                                </Box>
                              </Grid>
                              <Grid item xs={12}>
                                <Button
                                  className="success-btn"
                                  type="submit"
                                  variant="contained"
                                  color="success"
                                >
                                  Save Changes
                                </Button>
                              </Grid>
                            </Grid>
                          </Form>
                        )}
                      </Formik>
                    )}
                    {tabIndex === 1 &&
                      addressData.map((data) => (
                        <AddressComponent
                          key={data.address_id}
                          data={data}
                          fetchSuccess={fetchSuccess}
                          fetchError={fetchError}
                          handlePrimaryAddress={handlePrimaryAddress}
                          fetchAddressData={fetchAddressData}
                        />
                      ))}
                    {tabIndex === 2 && (
                      <Formik
                        initialValues={{
                          password: "",
                          newPassword: "",
                        }}
                        validationSchema={passwordValidationSchema}
                        onSubmit={async (values, { resetForm }) => {
                          try {
                            const response = await axios.put(
                              `/customer/password/${user?.userId}`,
                              values
                            );
                            resetForm();
                            fetchSuccess(response.data.message);
                          } catch (error) {
                            fetchError(error.response.data.error);
                          }
                        }}
                      >
                        {({ values, errors, touched, handleChange }) => (
                          <Form>
                            <Grid container spacing={3}>
                              <Grid item xs={12} sm={6}>
                                <TextField
                                  className="view-password"
                                  fullWidth
                                  placeholder="Current Password"
                                  type={showPassword ? "text" : "password"}
                                  name="password"
                                  value={values.password}
                                  onChange={handleChange}
                                  InputProps={{
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        <IconButton
                                          onClick={() =>
                                            setShowPassword(!showPassword)
                                          }
                                        >
                                          {showPassword ? (
                                            <VisibilityOff />
                                          ) : (
                                            <Visibility />
                                          )}
                                        </IconButton>
                                      </InputAdornment>
                                    ),
                                  }}
                                  error={!!errors.password && touched.password}
                                  helperText={
                                    touched.password && errors.password
                                  }
                                />
                              </Grid>
                              <Grid item xs={12} sm={6}>
                                <TextField
                                  fullWidth
                                  className="view-password"
                                  placeholder="New Password"
                                  type={showNewPassword ? "text" : "password"}
                                  name="newPassword"
                                  value={values.newPassword}
                                  onChange={handleChange}
                                  error={
                                    !!errors.newPassword && touched.newPassword
                                  }
                                  helperText={
                                    touched.newPassword && errors.newPassword
                                  }
                                  InputProps={{
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        <IconButton
                                          onClick={() =>
                                            setShowNewPassword(!showNewPassword)
                                          }
                                        >
                                          {showNewPassword ? (
                                            <VisibilityOff />
                                          ) : (
                                            <Visibility />
                                          )}
                                        </IconButton>
                                      </InputAdornment>
                                    ),
                                  }}
                                />
                              </Grid>
                              <Grid item xs={12}>
                                <Button
                                  className="success-btn"
                                  type="submit"
                                  variant="contained"
                                  color="success"
                                >
                                  Update Password
                                </Button>
                              </Grid>
                            </Grid>
                          </Form>
                        )}
                      </Formik>
                    )}
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </SoftBox>
        </Card>
      )}
      <Modal open={isEditOpen} onClose={handleClose}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            borderRadius: "8px",
            boxShadow: 24,
            p: 4,
            maxWidth: 500,
            maxHeight: "calc(100vh - 100px)",
            overflow: "auto",
            ...customScrollbarStyle,
          }}
        >
          <AddAddress
            states={states}
            cities={cities}
            areas={areas}
            setSelectedCity={setSelectedCity}
            setSelectedState={setSelectedState}
            fetchSuccess={fetchSuccess}
            fetchError={fetchError}
            fetchAddressData={fetchAddressData}
            userId={user?.userId}
            onClose={handleClose}
          />
        </Box>
      </Modal>
      <Modal
        open={otpModalOpen}
        onClose={handleCloseOtpModal}
        aria-labelledby="otp-modal"
        aria-describedby="otp-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            borderRadius: 4,
            boxShadow: 24,
            p: 4,
            bgcolor: "background.paper",
          }}
        >
          <Typography id="otp-modal" variant="h6" component="h2">
            Enter OTP
          </Typography>
          <TextField
            placeholder="OTP"
            variant="outlined"
            value={otp}
            onChange={(e) => setOtp(e.target.value)}
            sx={{ mt: 2, width: "100%" }}
          />
          <Button
            className="success-btn"
            onClick={() =>
              handleOtpSubmit({
                ...formikProps.values,
                email: newEmail,
                otp,
              })
            }
            variant="contained"
            color="success"
            sx={{ mt: 2 }}
          >
            Submit
          </Button>
        </Box>
      </Modal>
    </DashboardLayout>
  );
};

export default Profile;
