import React, { useCallback, useEffect, useState } from "react";
import axios from "../../api";
import {
  Card,
  TextField,
  FormControl,
  CircularProgress,
  Grid,
  Switch,
  FormHelperText,
  FormControlLabel,
  Autocomplete,
} from "@mui/material";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useSnackbar } from "components/AlertMessages/SnackbarContext";
import Locations from "examples/ReuseFunctions/Locations";
const validationSchema = Yup.object({
  name: Yup.string().required("Name is required"),

  phone: Yup.string()
    .matches(/^[0-9]{10}$/, "Enter a valid phone number")
    .required("Phone number is required"),
  email: Yup.string()
    .matches(
      /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
      "Invalid email format"
    )
    .typeError("Enter a valid email")
    .required("Email is required"),
  state_id: Yup.string().required("State is required"),
  city_id: Yup.string().required("City is required"),
  region_id: Yup.string().required("Region is required"),
  address_type: Yup.string().required("Address type is required"),
  address: Yup.string().required("Address is required"),
});

const EditCustomer = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [customer, setCustomer] = useState(null);
  const [loading, setLoading] = useState(true);
  const [initialState, setInitialState] = useState(null);
  const [initialCity, setInitialCity] = useState(null);
  const [initialRegion, setInitialRegion] = useState(null);
  const { fetchError, fetchSuccess } = useSnackbar();
  const { states, cities, areas, setSelectedState, setSelectedCity } =
    Locations();
  const fetchCustomer = useCallback(async () => {
    setLoading(true);
    try {
      const response = await axios.get(`/customer/${id}`);
      const { data } = response;
      setCustomer(data);
      setSelectedState(data?.state_id);
      setSelectedCity(data?.city_id);
      setInitialState({
        state_id: data?.state_id,
        state_name: data?.state_name,
      });
      setInitialCity({
        city_id: data?.city_id,
        city_name: data?.city_name,
      });
      setInitialRegion({
        region_id: data?.region_id,
        region_name: data?.region_name,
      });
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }, [id, setSelectedState, setSelectedCity]);

  useEffect(() => {
    fetchCustomer();
  }, [fetchCustomer]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {loading ? (
        <CircularProgress />
      ) : (
        <Card style={{ padding: "30px", width: "100%", borderRadius: "8px" }}>
          <SoftTypography variant="h5" style={{ marginBottom: "18px" }}>
            Edit Customer
          </SoftTypography>
          <Formik
            enableReinitialize
            initialValues={{
              name: customer?.name || "",
              email: customer?.email || "",
              phone: customer?.phone || "",
              is_priority: customer?.is_priority === 1 || false,
              is_active: customer?.is_active === 1 || false,
              state_id: customer?.state_id || "",
              city_id: customer?.city_id || "",
              region_id: customer?.region_id || "",
              address_type: customer?.address_type || "",
              address: customer?.address || "",
            }}
            validationSchema={validationSchema}
            onSubmit={async (values) => {
              const {
                state_id,
                city_id,
                region_id,
                address_type,
                address,
                ...rest
              } = values;
              const addressData = {
                state_id: state_id,
                city_id: city_id,
                region_id: region_id,
                address_type: address_type,
                address: address,
              };
              values.type = customer.type || null;
              try {
                await axios.put(`/customer/${id}`, rest);
                await axios.put(
                  `/address/${customer?.address_id}`,
                  addressData
                );
                fetchSuccess("Customer updated successfully");
                navigate("/customers-list");
              } catch (err) {
                fetchError(err.response.data.error);
              }
            }}
          >
            {({ errors, touched, handleChange, setFieldValue, values }) => (
              <Form
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "20px",
                }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                      <SoftTypography
                        component="label"
                        variant="caption"
                        fontWeight="bold"
                      >
                        Name
                      </SoftTypography>
                      <TextField
                        name="name"
                        value={values.name}
                        onChange={handleChange}
                        variant="outlined"
                        fullWidth
                        error={!!errors.name && touched.name}
                        helperText={touched.name && errors.name}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                      <SoftTypography
                        component="label"
                        variant="caption"
                        fontWeight="bold"
                      >
                        Phone Number
                      </SoftTypography>
                      <TextField
                        name="phone"
                        value={values.phone}
                        inputProps={{ maxLength: 10 }}
                        onChange={handleChange}
                        variant="outlined"
                        fullWidth
                        error={!!errors.phone && touched.phone}
                        helperText={touched.phone && errors.phone}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                      <SoftTypography
                        component="label"
                        variant="caption"
                        fontWeight="bold"
                      >
                        Email
                      </SoftTypography>
                      <TextField
                        name="email"
                        value={values.email}
                        onChange={handleChange}
                        variant="outlined"
                        fullWidth
                        error={!!errors.email && touched.email}
                        helperText={touched.email && errors.email}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={6} sm={3}>
                    <FormControl fullWidth>
                      <FormControlLabel
                        control={
                          <Switch
                            name="is_priority"
                            checked={values.is_priority}
                            onChange={(event) => {
                              setFieldValue(
                                "is_priority",
                                event.target.checked
                              );
                            }}
                            color="primary"
                          />
                        }
                        label={
                          <SoftTypography
                            component="span"
                            variant="caption"
                            fontWeight="bold"
                          >
                            Premium
                          </SoftTypography>
                        }
                      />
                      {touched.is_priority && errors.is_priority && (
                        <FormHelperText error>
                          {errors.is_priority}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid item xs={6} sm={3}>
                    <FormControl fullWidth>
                      <FormControlLabel
                        control={
                          <Switch
                            name="is_active"
                            checked={values.is_active}
                            onChange={(event) => {
                              setFieldValue("is_active", event.target.checked);
                            }}
                            color="primary"
                          />
                        }
                        label={
                          <SoftTypography
                            component="span"
                            variant="caption"
                            fontWeight="bold"
                          >
                            Status
                          </SoftTypography>
                        }
                      />
                      {touched.is_active && errors.is_active && (
                        <FormHelperText error>
                          {errors.is_active}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <FormControl fullWidth>
                      <SoftTypography
                        component="label"
                        variant="caption"
                        fontWeight="bold"
                      >
                        State
                      </SoftTypography>
                      <Autocomplete
                        id="state-autocomplete"
                        options={states || []}
                        getOptionLabel={(option) => option.state_name || ""}
                        value={values.state_id ? initialState : null}
                        onChange={(event, newValue) => {
                          setFieldValue("city_id", "");
                          setFieldValue("region_id", "");
                          setFieldValue(
                            "state_id",
                            newValue ? newValue.state_id : ""
                          );
                          setInitialState({
                            state_id: newValue?.state_id || null,
                            state_name: newValue?.state_name || null,
                          });
                          setSelectedState(newValue ? newValue.state_id : "");
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            name="state_id"
                            variant="outlined"
                            error={!!errors.state_id && touched.state_id}
                            helperText={touched.state_id && errors.state_id}
                            fullWidth
                          />
                        )}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <FormControl fullWidth>
                      <SoftTypography
                        component="label"
                        variant="caption"
                        fontWeight="bold"
                      >
                        City
                      </SoftTypography>
                      <Autocomplete
                        id="city-autocomplete"
                        options={cities || []}
                        getOptionLabel={(option) => option.city_name || ""}
                        value={values.city_id ? initialCity : null}
                        onChange={(event, newValue) => {
                          setFieldValue("region_id", "");
                          setFieldValue(
                            "city_id",
                            newValue ? newValue.city_id : ""
                          );
                          setInitialCity({
                            city_id: newValue?.city_id || null,
                            city_name: newValue?.city_name || null,
                          });
                          setSelectedCity(newValue ? newValue.city_id : "");
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            name="city_id"
                            variant="outlined"
                            error={!!errors.city_id && touched.city_id}
                            helperText={touched.city_id && errors.city_id}
                            fullWidth
                          />
                        )}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <FormControl fullWidth>
                      <SoftTypography
                        component="label"
                        variant="caption"
                        fontWeight="bold"
                      >
                        Area
                      </SoftTypography>
                      <Autocomplete
                        id="region-autocomplete"
                        options={areas || []}
                        getOptionLabel={(option) => option.region_name || ""}
                        value={values.region_id ? initialRegion : null}
                        onChange={(event, newValue) => {
                          setFieldValue(
                            "region_id",
                            newValue ? newValue.region_id : ""
                          );
                          setInitialRegion({
                            region_id: newValue?.region_id || null,
                            region_name: newValue?.region_name || null,
                          });
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            name="region_id"
                            variant="outlined"
                            error={!!errors.region_id && touched.region_id}
                            helperText={touched.region_id && errors.region_id}
                            fullWidth
                          />
                        )}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl fullWidth>
                      <SoftTypography
                        component="label"
                        variant="caption"
                        fontWeight="bold"
                      >
                        Address Type
                      </SoftTypography>
                      <TextField
                        name="address_type"
                        value={values.address_type}
                        onChange={handleChange}
                        variant="outlined"
                        fullWidth
                        error={!!errors.address_type && touched.address_type}
                        helperText={touched.address_type && errors.address_type}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl fullWidth>
                      <SoftTypography
                        component="label"
                        variant="caption"
                        fontWeight="bold"
                      >
                        Address
                      </SoftTypography>
                      <TextField
                        name="address"
                        value={values.address}
                        onChange={handleChange}
                        variant="outlined"
                        fullWidth
                        error={!!errors.address && touched.address}
                        helperText={touched.address && errors.address}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item>
                      <SoftButton
                        className="success-btn"
                        variant="gradient"
                        color="success"
                        type="submit"
                      >
                        Update
                      </SoftButton>
                    </Grid>
                    <Grid item>
                      <SoftButton
                        className="cancel-btn"
                        variant="gradient"
                        color="warning"
                        onClick={() => navigate(`/customers-list`)}
                      >
                        Cancel
                      </SoftButton>
                    </Grid>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </Card>
      )}
    </DashboardLayout>
  );
};

export default EditCustomer;
