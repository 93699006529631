import React from "react";
import { Grid, Typography, Box, IconButton, Modal } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const customScrollbarStyle = {
  "&::-webkit-scrollbar": {
    width: "8px",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "#44444461", // Lighter black color
    borderRadius: "8px", // Curved border
  },
  "&::-webkit-scrollbar-thumb:hover": {
    backgroundColor: "#666", // Lighter black color on hover
  },
};

const HistoryModal = ({ title, data, isOpen, onClose }) => {
  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      aria-labelledby="otp-modal"
      aria-describedby="otp-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          bgcolor: "background.paper",
          borderRadius: "8px",
          boxShadow: 24,
          p: 4,
          minWidth: 500,
          minHeight: 220,
          maxHeight: "calc(100vh - 100px)",
          overflow: "auto",
          ...customScrollbarStyle,
        }}
      >
        <IconButton
          onClick={onClose}
          sx={{
            position: "absolute",
            top: 8,
            right: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <Grid
          item
          xs={12}
          display={"flex"}
          justifyContent={"center"}
          className="view-ticket-title"
        >
          <Typography variant="h5" gutterBottom>
            {title} History
          </Typography>
        </Grid>
        {data ? (
          <Typography variant="h5">{data}</Typography>
        ) : (
          <Grid item xs={12} display={"flex"} justifyContent={"center"}>
            <Typography variant="h5">No {title} History</Typography>
          </Grid>
        )}
      </Box>
    </Modal>
  );
};

export default HistoryModal;
