import React, { useCallback, useEffect, useState } from "react";
import axios from "../../api";
import {
  Card,
  Button,
  TextField,
  FormControl,
  Grid,
  Box,
  Autocomplete,
} from "@mui/material";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import SoftTypography from "components/SoftTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useNavigate, useParams } from "react-router-dom";
import { useSnackbar } from "components/AlertMessages/SnackbarContext";
import SoftBox from "components/SoftBox";

const todayDate = new Date().toISOString().split("T")[0];

const validationSchema = Yup.object({
  subscription_type: Yup.string().required("Subscription type is required"),
  start_date: Yup.string()
    .matches(
      /^\d{4}-\d{2}-\d{2}$/,
      "Start date must be in the format YYYY-MM-DD"
    )
    .required("Start date is required")
    .test("is-valid-date", "Start date is not a valid date", (value) => {
      return !isNaN(new Date(value).getTime());
    }),
  end_date: Yup.string()
    .matches(/^\d{4}-\d{2}-\d{2}$/, "End date must be in the format YYYY-MM-DD")
    .required("End date is required")
    .test("is-valid-date", "End date is not a valid date", (value) => {
      return !isNaN(new Date(value).getTime());
    })
    .test(
      "is-greater-than-today",
      "End date must be greater than today",
      (value) => {
        if (!value) return true; // Skips validation if the value is not set yet
        const endDate = new Date(value);
        return endDate > new Date(todayDate);
      }
    ),
});

const EditSubscription = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [subscriptionData, setSubscriptionData] = useState(null);
  const { fetchError, fetchSuccess } = useSnackbar();

  const fetchSubscriptionData = useCallback(async () => {
    try {
      const response = await axios.get(`/subscription/${id}`);
      const { data } = response;
      setSubscriptionData(data);
    } catch (error) {
      console.error("Error:", error);
    }
  }, [id]);

  useEffect(() => {
    fetchSubscriptionData();
  }, [fetchSubscriptionData]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };
  const subscriptionTypes = ["AMC", "AMC with Parts", "Free"];
  const today = new Date().toISOString().split("T")[0];

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Card
        style={{
          padding: "30px",
          width: "100%",
          borderRadius: "8px",
        }}
      >
        <SoftTypography variant="h5" style={{ marginBottom: "18px" }}>
          Edit Subscription
        </SoftTypography>
        <Formik
          enableReinitialize
          initialValues={{
            subscription_type: subscriptionData?.subscription_type || null,
            start_date: formatDate(subscriptionData?.start_date),
            end_date: formatDate(subscriptionData?.end_date),
          }}
          validationSchema={validationSchema}
          onSubmit={async (values) => {
            try {
              const response = await axios.put(`/subscription/${id}`, values);
              fetchSuccess(response.data.message);
              navigate(`/subscriptions-list`);
            } catch (error) {
              fetchError(error.response.data.error);
            }
          }}
        >
          {({ errors, touched, setFieldValue, values }) => (
            <Form
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "20px",
              }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <SoftBox>
                    <SoftTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                    >
                      User
                    </SoftTypography>
                    <TextField
                      variant="outlined"
                      name="customer_id"
                      fullWidth
                      value={subscriptionData?.name}
                      disabled
                    />
                  </SoftBox>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <SoftBox>
                    <SoftTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                    >
                      Subscription Type
                    </SoftTypography>
                    <Autocomplete
                      options={subscriptionTypes || []}
                      getOptionLabel={(option) => option}
                      value={values?.subscription_type}
                      onChange={(event, value) =>
                        setFieldValue("subscription_type", value || "")
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          error={
                            !!errors.subscription_type &&
                            touched.subscription_type
                          }
                          helperText={
                            touched.subscription_type &&
                            errors.subscription_type
                          }
                        />
                      )}
                    />
                  </SoftBox>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <SoftTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                    >
                      Start Date
                    </SoftTypography>
                    <Field
                      className="date-field"
                      name="start_date"
                      as={TextField}
                      InputProps={{
                        inputProps: {
                          min: today,
                        },
                      }}
                      type="date"
                      fullWidth
                      variant="outlined"
                      error={!!errors.start_date && touched.start_date}
                      helperText={touched.start_date && errors.start_date}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <SoftTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                    >
                      End Date
                    </SoftTypography>
                    <Field
                      className="date-field"
                      name="end_date"
                      as={TextField}
                      InputProps={{
                        inputProps: {
                          min: values?.start_date || today,
                        },
                      }}
                      type="date"
                      fullWidth
                      variant="outlined"
                      error={!!errors.end_date && touched.end_date}
                      helperText={touched.end_date && errors.end_date}
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <Grid item xs={12} md={12}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Button
                    className="success-btn"
                    sx={{
                      position: "relative",
                      minWidth: 100,
                    }}
                    variant="contained"
                    type="submit"
                    color="success"
                  >
                    Update
                  </Button>

                  <Button
                    className="cancel-btn"
                    sx={{
                      position: "relative",
                      minWidth: 100,
                      ml: 2.5,
                    }}
                    variant="contained"
                    color="warning"
                    onClick={() => navigate(`/subscriptions-list`)}
                    type="cancel"
                  >
                    Cancel
                  </Button>
                </Box>
              </Grid>
            </Form>
          )}
        </Formik>
      </Card>
    </DashboardLayout>
  );
};

export default EditSubscription;
