import { Card, CardContent, CardMedia, Typography } from "@mui/material";
import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

function SlideCarousel({ carouselData }) {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  return (
    <Carousel
      responsive={responsive}
      infinite={true}
      autoPlay={true}
      autoPlaySpeed={2000}
    >
      {carouselData.map((data, index) => (
        <Item
          key={index}
          image={data?.image}
          title={data?.title}
          description={data?.description}
        />
      ))}
    </Carousel>
  );
}

function Item({ image, title, description }) {
  return (
    <Card sx={{ margin: "10px" }}>
      <CardMedia component="img" alt={`Slide ${image}`} image={image} />
      <CardContent>
        <Typography variant="h5" component="h2">
          {title}
        </Typography>
        <Typography variant="body2" color="textSecondary" component="p">
          {description}
        </Typography>
      </CardContent>
    </Card>
  );
}

export default SlideCarousel;
