import React from "react";
import { Stepper, Step, StepLabel, Box } from "@mui/material";

const StatusTracker = ({ trackingData }) => {
  var steps = ["Open", "ToDo", "In-Progress", "Done", "closed"];
  if ([4].includes(trackingData?.status_id)) {
    steps = ["Open", "ToDo", "In-Progress", "On-Hold", "Done", "closed"];
  }
  if ([5].includes(trackingData?.status_id)) {
    steps = ["Open", "ToDo", "In-Progress", "Pending", "Done", "closed"];
  }
  if ([8].includes(trackingData?.status_id)) {
    steps = ["Re-Open", "ToDo", "In-Progress", "Done", "closed"];
  }
  if ([9].includes(trackingData?.status_id)) {
    steps = ["Cancelled", "Open", "ToDo", "In-Progress", "Done", "closed"];
  }
  const getStatusIndex = (status) => {
    switch (status) {
      case 1:
        return 0;
      case 2:
        return 1;
      case 3:
        return 2;
      case 4:
      case 5:
      case 6:
        return 3;
      case 7:
        return 4;
      case 8:
      case 9:
        return 0;
      default:
        return 0;
    }
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Stepper
        activeStep={getStatusIndex(trackingData?.status_id)}
        alternativeLabel
      >
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
};

export default StatusTracker;
