import * as React from "react";
import MUIDataTable from "mui-datatables";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Box, Button, Card, IconButton, Modal, Tooltip } from "@mui/material";
import { Edit as EditIcon } from "@mui/icons-material";
import axios from "../../api";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import SoftBox from "components/SoftBox";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EmployeeEditTicket from "./EmployeeEditTicket";
import { useSnackbar } from "components/AlertMessages/SnackbarContext";
import StatusHistory from "examples/ReuseFunctions/StatusHistory";

const getMuiTheme = (theme) =>
  createTheme({
    components: {
      MUIDataTableHeadCell: {
        styleOverrides: {
          root: {
            backgroundColor: theme.palette.background.default,
          },
        },
      },
    },
  });
const customScrollbarStyle = {
  "&::-webkit-scrollbar": {
    width: "8px",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "#44444461",
    borderRadius: "8px",
  },
  "&::-webkit-scrollbar-thumb:hover": {
    backgroundColor: "#666",
  },
};
const options = {
  selectableRows: "none",
  selectableRowsHeader: false,
  elevation: 0,
};

const EmployeeTicketList = () => {
  const user = useSelector((state) => state.auth.user);
  const navigate = useNavigate();
  const [rows, setRows] = React.useState([]);
  const { fetchError, fetchSuccess } = useSnackbar();
  const location = useLocation();
  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "short", day: "numeric" };
    const date = new Date(dateString);
    return new Intl.DateTimeFormat("en-US", options).format(date);
  };

  const fetchData = React.useCallback(
    async (statusId) => {
      try {
        const response = await axios.get(`/tickets/employee/${user?.userId}`, {
          params: { status_id: statusId },
        });
        const { data } = response;
        setRows(data?.list);
      } catch (error) {
        console.error("Error: ", error);
      }
    },
    [user?.userId]
  );
  React.useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const status = queryParams.get("status_id");
    fetchData(status);
    // Use the query parameter as needed
  }, [location, fetchData]);

  const handleStatus = async (ticket) => {
    const statusdata = StatusHistory(
      ticket?.status_history,
      user?.name,
      ticket?.status_name
    );
    const ticketData = {
      status_id: 3,
      status_history: statusdata,
    };
    try {
      await axios.put(`/tickets/${ticket?.ticket_id}`, { ticketData });
      fetchData();
      fetchSuccess("Ticket updated to in-progress");
    } catch (err) {
      fetchError("Unable to Update Ticket");
    }
  };
  const columns = [
    { name: "category_name", label: "Category" },
    { name: "customer_name", label: "Customer" },
    { name: "customer_phone", label: "Phone" },
    {
      name: "priority_rank",
      label: "Priority",
      options: {
        filter: false,
        customBodyRender: (value) => {
          return (
            <span
              className="active-btn"
              style={{
                borderColor:
                  value === "High"
                    ? "#00bdaa"
                    : value === "Medium"
                    ? "#ffaa00"
                    : "#fe346e",
              }}
            >
              {value}
            </span>
          );
        },
      },
    },
    {
      name: "description",
      label: "Description",
      options: {
        filter: false,
        customBodyRender: (value) => {
          const displayValue =
            value?.length > 20 ? `${value.substring(0, 15)}...` : value;
          return (
            <Tooltip title={value}>
              <span>{displayValue}</span>
            </Tooltip>
          );
        },
      },
    },
    {
      name: "address",
      label: "Address",
      options: {
        filter: false,
        customBodyRender: (value, tableMeta) => {
          const { currentTableData, rowIndex } = tableMeta;
          const data = tableMeta?.tableData[currentTableData[rowIndex]?.index];
          const city = data?.city_name;
          const state = data?.state_name;
          const addressType = data?.address_type;
          const addressData = `${value}, ${city}, ${state},${addressType}`;

          const displayValue =
            addressData?.length > 20
              ? `${addressData.substring(0, 20)}...`
              : addressData;
          return (
            <Tooltip title={addressData}>
              <span>{displayValue}</span>
            </Tooltip>
          );
        },
      },
    },
    {
      name: "created_at",
      label: "Created At",
      options: {
        filter: false,
        customBodyRender: (value) => formatDate(value),
      },
    },
    { name: "status_name", label: "Status" },
    {
      name: "id",
      label: "Actions",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => {
          const { currentTableData, rowIndex } = tableMeta;
          const ticketData =
            tableMeta?.tableData[currentTableData[rowIndex]?.index];
          return (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <IconButton
                aria-label="edit"
                onClick={() =>
                  navigate(`/employee/ticket-view/${ticketData.ticket_id}`)
                }
              >
                <VisibilityIcon />
              </IconButton>
              {[3].includes(ticketData?.status_id) && (
                <IconButton
                  aria-label="edit"
                  onClick={() => handleOpen(ticketData)}
                >
                  <EditIcon />
                </IconButton>
              )}
              {ticketData?.status_id === 2 && (
                <Button
                  sx={{
                    position: "relative",
                  }}
                  size="small"
                  variant="contained"
                  onClick={() => {
                    handleStatus(ticketData);
                  }}
                >
                  Start
                </Button>
              )}
            </Box>
          );
        },
      },
    },
  ];

  const [isEditOpen, setIsEditOpen] = React.useState(false);
  const [selectedTicket, setSelectedTicket] = React.useState(null);

  const handleOpen = (ticketData) => {
    setSelectedTicket(ticketData);
    setIsEditOpen(true);
  };

  const handleClose = () => {
    setIsEditOpen(false);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Card
        style={{ padding: "24px", margin: "16px auto", maxWidth: "2000px" }}
      >
        <SoftBox
          sx={{
            "& .MuiTableRow-root:not(:last-child)": {
              "& td": {
                borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                  `${borderWidth[1]} solid ${borderColor}`,
              },
            },
          }}
        >
          <ThemeProvider theme={getMuiTheme}>
            <MUIDataTable
              className="manage-tickets"
              title={"Manage Tickets"}
              data={rows}
              columns={columns}
              options={options}
            />
          </ThemeProvider>
        </SoftBox>
      </Card>
      {isEditOpen && (
        <Modal open={isEditOpen} onClose={handleClose}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              bgcolor: "background.paper",
              borderRadius: "8px",
              boxShadow: 24,
              p: 4,
              maxWidth: 500,
              maxHeight: "calc(100vh - 100px)",
              overflow: "auto",
              ...customScrollbarStyle,
            }}
          >
            <EmployeeEditTicket
              employeeTicketData={selectedTicket}
              onClose={handleClose}
              fetchData={fetchData}
            />
          </Box>
        </Modal>
      )}
    </DashboardLayout>
  );
};

export default EmployeeTicketList;
