import React from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "../../../api";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { TextField, Button, Typography, Card } from "@mui/material";
import SoftTypography from "components/SoftTypography";
import SoftBox from "components/SoftBox";
import { useSnackbar } from "components/AlertMessages/SnackbarContext";
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import Footer from "../components/Footer";

const PasswordReset = () => {
  const { token } = useParams();
  const navigate = useNavigate();
  const { fetchError, fetchSuccess } = useSnackbar();

  const initialValues = {
    newPassword: "",
    confirmPassword: "",
  };

  const validationSchema = Yup.object().shape({
    newPassword: Yup.string()
      .required("Password is required")
      .min(6, "Password must be at least 6 characters")
      .matches(
        /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[!@#$%^&*()_+{}[\]:;<>,.?~\\/-])/,
        "Password must contain at least one letter, one number, and one special character"
      ),
    confirmPassword: Yup.string()
      .required("Please confirm your password")
      .oneOf([Yup.ref("newPassword"), null], "Passwords must match"),
  });

  const handleSubmit = async (values, { setSubmitting }) => {
    const { newPassword } = values;
    try {
      const response = await axios.post("/auth/reset-password", {
        token,
        newPassword,
      });
      fetchSuccess(response.data.message);
      navigate("/employee/sign-in");
    } catch (error) {
      fetchError(error.response?.data?.error);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <>
      <DefaultNavbar />
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: "50px",
          marginBottom: "50px",
          width: "100%",
        }}
      >
        <Card
          className="signin-form signin-admin-form"
          sx={{
            maxWidth: "30vw",
            width: "100%",
            padding: "30px",
          }}
        >
          <Typography variant="h5" align="center" gutterBottom>
            Reset Password
          </Typography>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({
              isSubmitting,
              touched,
              errors,
              handleChange,
              handleBlur,
              values,
            }) => (
              <Form>
                <SoftBox>
                  <SoftTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                  >
                    New Password
                  </SoftTypography>
                  <TextField
                    fullWidth
                    type="password"
                    name="newPassword"
                    variant="outlined"
                    value={values.newPassword}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.newPassword && Boolean(errors.newPassword)}
                    helperText={touched.newPassword && errors.newPassword}
                  />
                </SoftBox>
                <SoftBox>
                  <SoftTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                  >
                    Confirm Password
                  </SoftTypography>
                  <TextField
                    fullWidth
                    type="password"
                    name="confirmPassword"
                    variant="outlined"
                    value={values.confirmPassword}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={
                      touched.confirmPassword && Boolean(errors.confirmPassword)
                    }
                    helperText={
                      touched.confirmPassword && errors.confirmPassword
                    }
                  />
                </SoftBox>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={isSubmitting}
                  fullWidth
                  sx={{ mt: 2 }}
                >
                  Reset Password
                </Button>
              </Form>
            )}
          </Formik>
        </Card>
      </div>
      <Footer />
    </>
  );
};

export default PasswordReset;
