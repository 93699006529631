import * as React from "react";
import { useNavigate } from "react-router-dom";
import MUIDataTable from "mui-datatables";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Box, Card, IconButton } from "@mui/material";
import { Edit as EditIcon } from "@mui/icons-material";
import axios from "../../api";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import SoftBox from "components/SoftBox";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import SoftButton from "components/SoftButton";

const getMuiTheme = (theme) =>
  createTheme({
    components: {
      MUIDataTableHeadCell: {
        styleOverrides: {
          root: {
            backgroundColor: theme.palette.background.default,
          },
        },
      },
    },
  });

const options = {
  pagination: true,
  paginationPosition: "top",
  selectableRows: "none",
  selectableRowsHeader: false,
  elevation: 0,
};

const SubscriptionList = () => {
  const navigate = useNavigate();
  const [rows, setRows] = React.useState([]);
  const [error, setError] = React.useState(null);

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "short", day: "numeric" };
    const date = new Date(dateString);
    return new Intl.DateTimeFormat("en-US", options).format(date);
  };
  const columns = [
    { name: "name", label: "Customer" },
    { name: "lift_service_id", label: "Lift Id" },
    { name: "subscription_type", label: "Subscription Type" },
    {
      name: "start_date",
      label: "Start Date",
      options: {
        filter: false,
        customBodyRender: (value) => formatDate(value),
      },
    },
    {
      name: "end_date",
      label: "End Date",
      options: {
        filter: false,
        customBodyRender: (value) => formatDate(value),
      },
    },
    {
      name: "is_active",
      label: "Status",
      options: {
        filter: false,
        customBodyRender: (value) => (
          <span
            className="active-btn"
            style={{
              borderColor: value ? "#00bdaa" : "#fe346e",
            }}
          >
            {value ? "Active" : "Inactive"}
          </span>
        ),
      },
    },
    {
      name: "id",
      label: "Actions",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => {
          const { currentTableData, rowIndex } = tableMeta;
          const subscription =
            tableMeta?.tableData[currentTableData[rowIndex]?.index];
          return (
            <Box>
              {subscription.is_active ? (
                <IconButton
                  aria-label="edit"
                  onClick={() =>
                    navigate(
                      `/edit-subscription/${subscription?.subscription_id}`
                    )
                  }
                >
                  <EditIcon />
                </IconButton>
              ) : (
                ""
              )}
            </Box>
          );
        },
      },
    },
  ];

  const fetchData = React.useMemo(
    () => async () => {
      try {
        const response = await axios.get("/subscription");
        setRows(response?.data);
      } catch (error) {
        setError(error);
      }
    },
    []
  );

  React.useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Card
        style={{ padding: "24px", margin: "16px auto", maxWidth: "2000px" }}
      >
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <SoftButton
            variant="gradient"
            color="info"
            onClick={() => navigate(`/add-subscription`)}
          >
            Add Subscription
          </SoftButton>
        </div>
        <SoftBox
          sx={{
            "& .MuiTableRow-root:not(:last-child)": {
              "& td": {
                borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                  `${borderWidth[1]} solid ${borderColor}`,
              },
            },
          }}
        >
          <ThemeProvider theme={getMuiTheme}>
            <MUIDataTable
              className="manage-subscriptions"
              title={"Manage Subscriptions"}
              data={rows}
              columns={columns}
              options={options}
            />
          </ThemeProvider>
          {error && <div>Error: {error.message}</div>}
        </SoftBox>
      </Card>
    </DashboardLayout>
  );
};

export default SubscriptionList;
