import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import MiniStatisticsCard from "examples/Cards/StatisticsCards/MiniStatisticsCard";
import axios from "../../api";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const Dashboard = () => {
  const [counts, setCounts] = useState({});
  const [totalCount, setTotalCount] = useState(null);
  const navigate = useNavigate();
  const user = useSelector((state) => state.auth.user);

  useEffect(() => {
    const fetchTicketCounts = async () => {
      var response;
      try {
        if (user.Role.includes("employee")) {
          response = await axios.get(
            `/tickets/employee/ticket-counts/${user?.userId}`
          );
        } else {
          response = await axios.get("/tickets/ticket-counts");
        }
        setCounts(response.data.list);
        setTotalCount(response?.data?.total_count);
      } catch (error) {
        console.error(error);
      }
    };

    fetchTicketCounts();
  }, [user]);
  const handleClick = (status_id) => {
    if (user.Role.includes("employee")) {
      navigate(`/employee-tickets-list?status_id=${status_id}`);
    } else {
      navigate(`/admin/tickets-list?status_id=${status_id}`);
    }
  };
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} md={4} xl={3}>
          <div
            onClick={() =>
              navigate(
                user.Role.includes("employee")
                  ? `/employee-tickets-list`
                  : `/admin/tickets-list`
              )
            }
            style={{ cursor: "pointer" }}
          >
            <MiniStatisticsCard
              title={{ text: "Total Tickets" }}
              count={totalCount}
              percentage={{ color: "primary", text: "Tickets" }}
              icon={{ color: "primary", component: "🎟️" }} // Using the ticket emoji
            />
          </div>
        </Grid>
        {Object.entries(counts).map(([status, { status_id, total_count }]) => (
          <Grid item xs={12} sm={6} md={4} xl={3} key={status_id}>
            <div
              onClick={() => handleClick(status_id)}
              style={{ cursor: "pointer" }}
            >
              <MiniStatisticsCard
                title={{ text: status }}
                count={total_count}
                percentage={{ color: "primary", text: "Tickets" }}
                icon={{ color: "primary", component: "🎟️" }} // Using the ticket emoji
              />
            </div>
          </Grid>
        ))}
      </Grid>
    </DashboardLayout>
  );
};

export default Dashboard;
