import React, { useState, useEffect, useCallback } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import {
  TextField,
  Button,
  Grid,
  Card,
  Box,
  Autocomplete,
  IconButton,
} from "@mui/material";
import axios from "../../api";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import { useNavigate, useParams } from "react-router-dom";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useLocation } from "react-router-dom";
import { useSnackbar } from "components/AlertMessages/SnackbarContext";
import AWS from "aws-sdk";
import { useDropzone } from "react-dropzone";
import AssigneeHistory from "examples/ReuseFunctions/AssigneeHistory";
import StatusHistory from "examples/ReuseFunctions/StatusHistory";
import { useSelector } from "react-redux";
import DeleteIcon from "@mui/icons-material/Delete";

const validationSchema = Yup.object({
  category_id: Yup.string().required("Category is required"),
  description: Yup.string().required("Description is required"),
});
const conditionValidationSchema = Yup.object({
  category_id: Yup.string().required("Category is required"),
  description: Yup.string().required("Description is required"),
  priority_rank: Yup.string().required("Priority rank is required"),
});
const s3 = new AWS.S3({
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
  region: process.env.REACT_APP_AWS_REGION,
});
const AdminEditTicket = () => {
  const user = useSelector((state) => state.auth.user);
  const [categories, setCategories] = useState([]);
  const [employees, setEmployees] = useState(null);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [ticketStatuses, setTicketStatuses] = useState(null);
  const [ticketResponseData, setTicketResponseData] = useState(null);
  const [initialCategory, setInitialCategory] = useState(null);
  const [initialStatus, setinitialStatus] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  const { fetchError, fetchSuccess } = useSnackbar();

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const regionId = queryParams.get("regionId");
  const navigate = useNavigate();
  const { id } = useParams();

  const handleDelete = (index) => {
    setUploadedFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  const fetchData = useCallback(async () => {
    try {
      const [
        categoriesResponse,
        ticketResponse,
        employeeslist,
        ticketStatuses,
      ] = await Promise.all([
        axios.get("/category", {
          params: {
            is_active: 1,
          },
        }),
        axios.get(`/tickets/${id}`),
        axios.get(`/employee/region/${regionId}`, {
          params: {
            role_id: 4,
          },
        }),
        axios.get("/ticket-statuses"),
      ]);
      setCategories(categoriesResponse.data.list);
      // setAddresses(addressesResponse.data.list);
      setEmployees(employeeslist.data);
      setTicketStatuses(
        ticketStatuses.data.filter(
          (option) => option.status_id !== 1 && option.status_id !== 2
        )
      );
      const { data } = ticketResponse;
      const ticketData = data?.list;
      setinitialStatus({
        status_id: ticketData.status_id || "",
        status_name: ticketData?.status_name,
      });
      setTicketResponseData(ticketData);
      setSelectedEmployee({
        employee_id: ticketData?.assigned_employee_id || null,
        name: ticketData.employee_name,
      });
      setInitialCategory({
        category_id: ticketData.category_id || "",
        name: ticketData?.category_name,
      });
    } catch (err) {
      console.error("Error fetching data", err);
    }
  }, [id, regionId]);
  useEffect(() => {
    fetchData();
  }, [fetchData]);
  const handleMediaDelete = async (mediaId) => {
    try {
      const response = await axios.delete(`/tickets/media/${mediaId}`);
      fetchData();
      fetchSuccess(response.data.message);
    } catch (error) {
      fetchError(error.response.data.error);
    }
  };
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const dropzone = useDropzone({
    accept: {
      "image/png": [".png", ".jpeg", ".jpg"],
      "video/mp4": [".mp4"],
    },
    onDrop: (acceptedFiles) => {
      // Append new files to the existing uploadedFiles array
      setUploadedFiles((prevFiles) => [
        ...prevFiles,
        ...acceptedFiles?.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        ),
      ]);
    },
  });
  const priorityRanks = ["High", "Medium", "Low"];

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Card
        style={{
          padding: "30px",
          width: "100%",
          borderRadius: "8px",
        }}
      >
        <SoftTypography variant="h5" style={{ marginBottom: "18px" }}>
          Edit Ticket
        </SoftTypography>
        <Formik
          enableReinitialize
          initialValues={{
            category_id: ticketResponseData?.category_id || "",
            description: ticketResponseData?.description || "",
            customer_id: ticketResponseData?.customer_id || "",
            assigned_employee_id:
              ticketResponseData?.assigned_employee_id || null,
            file_name: ticketResponseData?.file_name || null,
            file_type: ticketResponseData?.file_type || null,
            status_id: ticketResponseData?.status_id || null,
            pending_reason: ticketResponseData?.pending_reason || null,
            priority_rank: ticketResponseData?.priority_rank || null,
          }}
          validationSchema={
            selectedEmployee ? conditionValidationSchema : validationSchema
          }
          onSubmit={async (values) => {
            setSubmitting(true);
            const ticketData = {
              category_id: values.category_id,
              description: values.description,
              customer_id: values?.customer_id,
              assigned_employee_id: selectedEmployee?.employee_id,
              status_id: values?.status_id || "",
              pending_reason: values?.pending_reason || null,
              priority_rank: values?.priority_rank || null,
            };
            if (values?.status_id === ticketResponseData?.status_id) {
              delete ticketData.status_id;
            }
            if (
              ticketResponseData?.assigned_employee_id !==
              values?.assigned_employee_id
            ) {
              const assignData = AssigneeHistory(
                ticketResponseData?.assignee_history,
                selectedEmployee?.name
              );
              ticketData.assignee_history = assignData;
            }
            if (ticketResponseData?.status_id !== values?.status_id) {
              const statusData = StatusHistory(
                ticketResponseData?.status_history,
                user?.name,
                initialStatus?.status_name
              );
              ticketData.status_history = statusData;
            }
            let updatedMediaData = [];
            try {
              if (uploadedFiles && uploadedFiles.length > 0) {
                updatedMediaData = await Promise.all(
                  uploadedFiles.map(async (file) => {
                    const fileName = `${Date.now()}-${file.name}`;

                    const params = {
                      Bucket: process.env.REACT_APP_AWS_BUCKET_NAME,
                      Key: fileName,
                      Body: file,
                      ContentType: file.type,
                    };

                    let type = file.type.split("/")[0];
                    if (type === "image") {
                      type = "Photo";
                    }

                    const mediaData = {
                      file_name: fileName,
                      file_type: type,
                      file_path: fileName,
                    };

                    return new Promise((resolve, reject) => {
                      s3.upload(params, (err, data) => {
                        if (err) {
                          console.error("Error uploading file:", err);
                          reject(err);
                        } else {
                          resolve(mediaData);
                        }
                      });
                    });
                  })
                );
                try {
                  const response = await axios.put(`/tickets/${id}`, {
                    ticketData,
                    mediaData: updatedMediaData,
                  });
                  navigate(`/admin/tickets-list`);
                  fetchSuccess(response.data.message);
                } catch (error) {
                  fetchError(error.response.data.error);
                } finally {
                  setSubmitting(false);
                }
              } else {
                try {
                  const response = await axios.put(`/tickets/${id}`, {
                    ticketData,
                  });
                  navigate(`/admin/tickets-list`);
                  fetchSuccess(response.data.message);
                } catch (error) {
                  fetchError(error.response.data.error);
                } finally {
                  setSubmitting(false);
                }
              }
            } catch (error) {
              fetchError(error.response.data.error);
              setSubmitting(false);
            }
          }}
        >
          {({ errors, touched, setFieldValue, values }) => (
            <Form
              class="edit-ticket-form"
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "20px",
              }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <SoftBox>
                    <SoftTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                    >
                      Category
                    </SoftTypography>
                    <Autocomplete
                      options={categories || []}
                      getOptionLabel={(option) => option.name}
                      value={values.category_id ? initialCategory : null}
                      onChange={(event, value) =>
                        setFieldValue("category_id", value?.category_id || "")
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          error={!!errors.category_id && touched.category_id}
                          helperText={touched.category_id && errors.category_id}
                        />
                      )}
                    />
                  </SoftBox>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <SoftBox>
                    <SoftTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                    >
                      Description
                    </SoftTypography>
                    <Field
                      name="description"
                      as={TextField}
                      variant="outlined"
                      fullWidth
                      error={!!errors.description && touched.description}
                      helperText={touched.description && errors.description}
                    />
                  </SoftBox>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <SoftBox>
                    <SoftTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                    >
                      Assignee
                    </SoftTypography>
                    <Autocomplete
                      options={employees || []}
                      getOptionLabel={(option) => option.name}
                      value={
                        values?.assigned_employee_id ? selectedEmployee : null
                      }
                      onChange={(event, value) => {
                        setFieldValue(
                          "assigned_employee_id",
                          value?.employee_id || null
                        );
                        setSelectedEmployee(value);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          error={
                            errors.assigned_employee_id &&
                            touched.assigned_employee_id
                          }
                          helperText={
                            touched.assigned_employee_id &&
                            errors.assigned_employee_id
                          }
                        />
                      )}
                    />
                  </SoftBox>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <SoftBox>
                    <SoftTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                    >
                      Status
                    </SoftTypography>
                    <Autocomplete
                      options={ticketStatuses || []}
                      // getOptionLabel={(option) => option.status_name}
                      getOptionLabel={(option) => {
                        if (option.status_id !== 1 && option.status_id !== 2) {
                          return option.status_name;
                        } else {
                          return values.status_id === 2 ? "ToDo" : "Open";
                        }
                      }}
                      value={values.status_id ? initialStatus : null}
                      onChange={(event, value) => {
                        setFieldValue("status_id", value?.status_id || "");
                        setinitialStatus(value);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          error={!!errors.status_id && touched.status_id}
                          helperText={touched.status_id && errors.status_id}
                        />
                      )}
                    />
                  </SoftBox>
                </Grid>
                {values?.assigned_employee_id ? (
                  <Grid item xs={6} sm={4}>
                    <SoftBox>
                      <SoftTypography
                        component="label"
                        variant="caption"
                        fontWeight="bold"
                      >
                        Priority Rank
                      </SoftTypography>
                      <Autocomplete
                        options={priorityRanks || []}
                        getOptionLabel={(option) => option}
                        value={values.priority_rank}
                        onChange={(event, value) => {
                          setFieldValue("priority_rank", value || null);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            error={
                              errors.priority_rank && touched.priority_rank
                            }
                            helperText={
                              touched.priority_rank && errors.priority_rank
                            }
                          />
                        )}
                      />
                    </SoftBox>
                  </Grid>
                ) : null}
                <Grid item xs={12} md={12}>
                  <div
                    className={
                      ticketResponseData?.multimedia.length > 0 ||
                      uploadedFiles?.length > 0
                        ? "upload-media"
                        : ""
                    }
                  >
                    {ticketResponseData?.multimedia && (
                      <div>
                        {ticketResponseData.multimedia.map((media, index) => (
                          <div
                            className="media-container"
                            key={index}
                            style={{ position: "relative" }}
                          >
                            {media.file_type === "Photo" ? (
                              <img
                                className="upload-img"
                                src={`${process.env.REACT_APP_CLOUD_FRONT_URL}/${media.file_name}`}
                                alt={`media-${index}`}
                              />
                            ) : (
                              <video
                                className="upload-video"
                                controls
                                width="100%"
                                height="140px"
                              >
                                <source
                                  src={`${process.env.REACT_APP_CLOUD_FRONT_URL}/${media.file_name}`}
                                  type="video/mp4"
                                />
                              </video>
                            )}
                            <IconButton
                              onClick={() =>
                                handleMediaDelete(media?.multimedia_id)
                              }
                              style={{ position: "absolute", top: 5, right: 5 }}
                              color="secondary"
                            >
                              <DeleteIcon />
                            </IconButton>
                          </div>
                        ))}
                      </div>
                    )}
                    {uploadedFiles.map((file, index) => (
                      <div
                        className="media-container"
                        key={index}
                        style={{ position: "relative" }}
                      >
                        {file.preview &&
                          (file.type.charAt(0) === "i" ? (
                            <img src={file.preview} alt={`media-${index}`} />
                          ) : (
                            <video controls width="100%" height="140px">
                              <source src={file.preview} type="video/mp4" />
                            </video>
                          ))}
                        <IconButton
                          onClick={() => handleDelete(index)}
                          style={{ position: "absolute", top: 5, right: 5 }}
                          color="secondary"
                        >
                          <DeleteIcon />
                        </IconButton>
                      </div>
                    ))}
                  </div>
                  <Box
                    sx={{ cursor: "pointer", width: "fit-content" }}
                    {...dropzone.getRootProps()}
                  >
                    <Box>
                      <label htmlFor="image-upload-input">
                        <input
                          name="file_name"
                          {...dropzone.getInputProps({
                            name: "file_name",
                          })}
                        />
                        <Button
                          className="upload-btn"
                          component="span"
                          variant="contained"
                          color="primary"
                        >
                          Add/Upload File
                        </Button>
                      </label>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
              <Grid item xs={12} md={12}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Button
                    className="success-btn"
                    sx={{
                      position: "relative",
                      minWidth: 100,
                    }}
                    variant="contained"
                    type="submit"
                    disabled={submitting}
                    color="success"
                  >
                    Update
                  </Button>

                  <Button
                    className="cancel-btn"
                    sx={{
                      position: "relative",
                      minWidth: 100,
                      ml: 2.5,
                    }}
                    variant="contained"
                    color="warning"
                    onClick={() => navigate(`/admin/tickets-list`)}
                    type="cancel"
                  >
                    Cancel
                  </Button>
                </Box>
              </Grid>
            </Form>
          )}
        </Formik>
      </Card>
    </DashboardLayout>
  );
};

export default AdminEditTicket;
