import * as React from "react";
import { useNavigate } from "react-router-dom";
import MUIDataTable from "mui-datatables";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Box, Button, Card, IconButton, Tooltip } from "@mui/material";
import { Edit as EditIcon } from "@mui/icons-material";
import axios from "../../api";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import SoftBox from "components/SoftBox";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import SoftButton from "components/SoftButton";
import { useSelector } from "react-redux";
import { useSnackbar } from "components/AlertMessages/SnackbarContext";
import RatingComponent from "./Rating";
import VisibilityIcon from "@mui/icons-material/Visibility";
const getMuiTheme = (theme) =>
  createTheme({
    components: {
      MUIDataTable: {
        styleOverrides: {
          responsiveScroll: {
            maxHeight: "unset",
            overflowX: "auto",
          },
          responsiveScrollFullHeight: {
            maxHeight: "unset",
            overflowX: "auto",
          },
          responsiveScrollMaxHeight: {
            maxHeight: "unset",
            overflowX: "auto",
          },
        },
      },
      MUIDataTableHeadCell: {
        styleOverrides: {
          root: {
            backgroundColor: theme.palette.background.default,
          },
        },
      },
    },
  });

const TicketList = () => {
  const { fetchError, fetchSuccess } = useSnackbar();
  const navigate = useNavigate();
  const [rows, setRows] = React.useState([]);
  const user = useSelector((state) => state.auth.user);
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [ticketData, setticketData] = React.useState(null);
  const options = {
    selectableRows: "none",
    selectableRowsHeader: false,
    elevation: 0,
    setRowProps: (row, dataIndex) => {
      const isEscalated = rows[dataIndex]?.is_escalated;
      return {
        style: {
          backgroundColor: isEscalated ? "#ffaba7" : null,
        },
      };
    },
  };
  const formatDate = (dateString) => {
    const formateOptions = {
      year: "numeric",
      month: "short",
      day: "numeric",
    };
    const date = new Date(dateString);
    return new Intl.DateTimeFormat("en-US", formateOptions).format(date);
  };

  const handleTicketCancel = async (ticket_id) => {
    const ticketData = {
      status_id: 9,
    };
    try {
      await axios.put(`/tickets/${ticket_id}`, { ticketData });
      fetchData();
      fetchSuccess("Ticket Cancelled Successfully");
    } catch (err) {
      console.error("Error: ", err);
      fetchError("Unable to Cancel Ticket");
    }
  };
  const handleEscalationUpdate = async (ticket_id) => {
    const ticketData = {
      is_escalated: 1,
    };
    try {
      await axios.put(`/tickets/escalation/${ticket_id}`, ticketData);
      fetchData();
      fetchSuccess("Ticket escalated Successfully");
    } catch (err) {
      console.error("Error: ", err);
      fetchError("Unable to escalate Ticket");
    }
  };
  const handleRatingOpen = (data) => {
    setticketData(data);
    setIsModalOpen(true);
  };

  const handleRatingClose = () => {
    setIsModalOpen(false);
  };
  const columns = [
    { name: "category_name", label: "Category" },
    {
      name: "description",
      label: "Description",
      options: {
        filter: false,
        customBodyRender: (value) => {
          const displayValue =
            value?.length > 20 ? `${value.substring(0, 20)}...` : value;
          return (
            <Tooltip title={value}>
              <span>{displayValue}</span>
            </Tooltip>
          );
        },
      },
    },
    {
      name: "address",
      label: "Address",
      options: {
        filter: false,
        customBodyRender: (value, tableMeta) => {
          const { currentTableData, rowIndex } = tableMeta;
          const data = tableMeta?.tableData[currentTableData[rowIndex]?.index];
          const city = data?.city_name;
          const state = data?.state_name;
          const addressType = data?.address_type;
          const addressData = `${value}, ${city}, ${state},${addressType}`;

          const displayValue =
            addressData?.length > 20
              ? `${addressData.substring(0, 20)}...`
              : addressData;
          return (
            <Tooltip title={addressData}>
              <span>{displayValue}</span>
            </Tooltip>
          );
        },
      },
    },
    { name: "employee_name", label: "Assignee" },
    {
      name: "created_at",
      label: "Created At",
      options: {
        filter: false,
        customBodyRender: (value) => formatDate(value),
      },
    },
    { name: "status_name", label: "Status" },
    {
      name: "id",
      label: "Actions",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => {
          const { currentTableData, rowIndex } = tableMeta;
          const ticketData =
            tableMeta?.tableData[currentTableData[rowIndex]?.index];
          return (
            <>
              <IconButton
                className="mt-eye-btn"
                aria-label="view"
                onClick={() =>
                  navigate(`/customer/ticket-view/${ticketData.ticket_id}`)
                }
              >
                <VisibilityIcon />
              </IconButton>
              {[3, 4, 5].includes(ticketData?.status_id) &&
              !ticketData?.is_escalated ? (
                <Button
                  sx={{
                    position: "relative",
                  }}
                  variant="contained"
                  size="small"
                  onClick={() => {
                    handleEscalationUpdate(ticketData?.ticket_id);
                  }}
                >
                  Escalation
                </Button>
              ) : null}
              {!ticketData?.assigned_employee_id ? (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <IconButton
                    aria-label="edit"
                    onClick={() =>
                      navigate(`/customer/edit-ticket/${ticketData?.ticket_id}`)
                    }
                  >
                    <EditIcon />
                  </IconButton>
                  {ticketData?.status_id !== 9 && (
                    <Button
                      sx={{
                        position: "relative",
                      }}
                      variant="contained"
                      size="small"
                      onClick={() => {
                        handleTicketCancel(ticketData?.ticket_id);
                      }}
                    >
                      Cancel
                    </Button>
                  )}
                </Box>
              ) : (
                ticketData?.status_id === 6 && (
                  <Button
                    sx={{
                      position: "relative",
                    }}
                    variant="contained"
                    size="small"
                    onClick={() => {
                      handleRatingOpen(ticketData);
                    }}
                  >
                    Rating
                  </Button>
                )
              )}
            </>
          );
        },
      },
    },
  ];

  const fetchData = React.useMemo(
    () => async () => {
      try {
        const response = await axios.get(`/tickets/customer/${user.userId}`);
        setRows(response?.data?.list);
      } catch (error) {
        console.error(error);
      }
    },
    [user.userId]
  );

  React.useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Card
        style={{ padding: "24px", margin: "16px auto", maxWidth: "2000px" }}
      >
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <SoftButton
            variant="gradient"
            color="info"
            onClick={() => navigate(`/add-ticket`)}
          >
            Add Ticket
          </SoftButton>
        </div>
        <SoftBox
          sx={{
            "& .MuiTableRow-root:not(:last-child)": {
              "& td": {
                borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                  `${borderWidth[1]} solid ${borderColor}`,
              },
            },
          }}
        >
          <ThemeProvider theme={getMuiTheme}>
            <MUIDataTable
              className="manage-tickets"
              title={"Manage Tickets"}
              data={rows}
              columns={columns}
              options={options}
            />
          </ThemeProvider>
        </SoftBox>
      </Card>
      {isModalOpen && (
        <RatingComponent
          onClose={handleRatingClose}
          isOpen={isModalOpen}
          ratingData={ticketData}
          fetchData={fetchData}
        />
      )}
    </DashboardLayout>
  );
};

export default TicketList;
