import React, { useEffect, useRef, useState } from "react";
import axios from "../../api";
import { Card, Button, TextField, Grid, Box } from "@mui/material";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import Typography from "@mui/material/Typography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "components/AlertMessages/SnackbarContext";
import SoftTypography from "components/SoftTypography";
import SoftBox from "components/SoftBox";
import AWS from "aws-sdk";
import { useDropzone } from "react-dropzone";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const validationSchema = Yup.object({
  page_name: Yup.string().required("Name is required"),
  page_title: Yup.string().required("Title is required"),
  page_description: Yup.string().required("Description is required"),
});

const s3 = new AWS.S3({
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
  region: process.env.REACT_APP_AWS_REGION,
});

const AddPage = () => {
  const navigate = useNavigate();
  const editorRef = useRef(null);
  const { fetchError, fetchSuccess } = useSnackbar();
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const dropzone = useDropzone({
    accept: {
      "image/png": [".png", ".jpeg", ".jpg"],
    },
    onDrop: (acceptedFiles) => {
      // Append new files to the existing uploadedFiles array
      setUploadedFiles((prevFiles) => [
        ...prevFiles,
        ...acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        ),
      ]);
    },
  });

  useEffect(() => {
    setUploadedFiles(dropzone.acceptedFiles);
  }, [dropzone.acceptedFiles]);
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Card
        style={{
          padding: "30px",
          width: "100%",
          borderRadius: "8px",
        }}
      >
        <Typography variant="h5" style={{ marginBottom: "18px" }}>
          Add New Page
        </Typography>
        <Formik
          initialValues={{
            page_name: "",
            page_title: "",
            page_description: "",
            page_image: "",
          }}
          validationSchema={validationSchema}
          onSubmit={async (values) => {
            try {
              if (uploadedFiles && uploadedFiles.length > 0) {
                const file = uploadedFiles[0];
                const fileName = `${Date.now()}-${file.name}`;
                values.page_image = fileName;
                const params = {
                  Bucket: process.env.REACT_APP_AWS_BUCKET_NAME,
                  Key: fileName,
                  Body: file,
                  ContentType: file.type,
                };

                s3.upload(params, async (err, data) => {
                  if (err) {
                    console.error("Error uploading file:", err);
                  } else {
                    try {
                      const response = await axios.post("/pages", values);
                      navigate("/pages-list");
                      fetchSuccess(response.data.message);
                    } catch (error) {
                      fetchError(error.response.data.error);
                    }
                  }
                });
              } else {
                const response = await axios.post("/pages", values);
                navigate(`/pages-list`);
                fetchSuccess(response.data.message);
              }
            } catch (error) {
              fetchError(error.response.data.error);
            }
          }}
        >
          {({
            values,
            errors,
            touched,
            setFieldValue,
            handleChange,
            handleBlur,
          }) => (
            <Form
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "20px",
              }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <SoftBox>
                    <SoftTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                    >
                      Page Name
                    </SoftTypography>
                    <TextField
                      name="page_name"
                      fullWidth
                      variant="outlined"
                      value={values.page_name}
                      onChange={handleChange}
                      error={!!errors.page_name && touched.page_name}
                      helperText={touched.page_name && errors.page_name}
                    />
                  </SoftBox>
                </Grid>
                <Grid item xs={12}>
                  <SoftBox>
                    <SoftTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                    >
                      Page Title
                    </SoftTypography>
                    <TextField
                      name="page_title"
                      fullWidth
                      variant="outlined"
                      value={values.page_title}
                      onChange={handleChange}
                      error={!!errors.page_title && touched.page_title}
                      helperText={touched.page_title && errors.page_title}
                    />
                  </SoftBox>
                </Grid>
                <Grid item xs={12}>
                  <SoftBox>
                    <SoftTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                    >
                      Page Description
                    </SoftTypography>
                    <ReactQuill
                      ref={editorRef}
                      value={values.page_description}
                      onChange={(content, delta, source, editor) => {
                        setFieldValue("page_description", content);
                      }}
                      onBlur={(range, source, quill) => {
                        handleBlur({ target: { name: "page_description" } });
                      }}
                      modules={{
                        toolbar: [
                          [{ header: "1" }, { header: "2" }, { font: [] }],
                          [{ list: "ordered" }, { list: "bullet" }],
                          [
                            "bold",
                            "italic",
                            "underline",
                            "strike",
                            "blockquote",
                          ],
                          [{ align: [] }],
                          ["link", "image"],
                          ["clean"],
                        ],
                      }}
                      formats={[
                        "header",
                        "font",
                        "list",
                        "bold",
                        "italic",
                        "underline",
                        "strike",
                        "blockquote",
                        "align",
                        "link",
                        "image",
                      ]}
                    />
                    {touched.page_description && errors.page_description && (
                      <div
                        className="error"
                        style={{
                          color: "#ea0606",
                          fontSize: "13px",
                          marginLeft: "13px",
                          marginTop: "6px",
                        }}
                      >
                        {errors.page_description}
                      </div>
                    )}
                  </SoftBox>
                </Grid>
              </Grid>
              <Grid item xs={12} md={6}>
                {uploadedFiles.length > 0 && uploadedFiles[0]?.preview && (
                  <div className="upload-media">
                    {uploadedFiles[0]?.preview && (
                      <div
                        className="media-container"
                        style={{ position: "relative" }}
                      >
                        {
                          <img
                            src={uploadedFiles[0]?.preview}
                            alt="media"
                            className="upload-img"
                          />
                        }
                      </div>
                    )}
                  </div>
                )}
                <Box
                  sx={{ cursor: "pointer", width: "fit-content" }}
                  {...dropzone.getRootProps()}
                >
                  <Box>
                    <label htmlFor="image-upload-input">
                      <input
                        name="page_image"
                        {...dropzone.getInputProps({
                          name: "page_image",
                        })}
                      />
                      <Button
                        component="span"
                        variant="contained"
                        color="primary"
                        className="upload-btn"
                      >
                        Upload File
                      </Button>
                    </label>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} md={12}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Button
                    className="success-btn"
                    sx={{
                      position: "relative",
                      minWidth: 100,
                    }}
                    variant="contained"
                    type="submit"
                    color="success"
                  >
                    Save
                  </Button>
                  <Button
                    className="cancel-btn"
                    sx={{
                      position: "relative",
                      minWidth: 100,
                      ml: 2.5,
                    }}
                    variant="contained"
                    color="warning"
                    onClick={() => navigate("/pages-list")}
                    type="button"
                  >
                    Cancel
                  </Button>
                </Box>
              </Grid>
            </Form>
          )}
        </Formik>
      </Card>
    </DashboardLayout>
  );
};

export default AddPage;
