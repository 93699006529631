import PageLayout from "examples/LayoutContainers/PageLayout";
import Footer from "layouts/authentication/components/Footer";
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import { Box, Container, Grid, TextField, Typography } from "@mui/material";
import SoftButton from "components/SoftButton";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import { Email, LocationOnOutlined, Phone } from "@mui/icons-material";

const Contact = () => {
  return (
    <PageLayout>
      <DefaultNavbar />

      {/* <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          backgroundColor: ({ palette: { white }, functions: { rgba } }) =>
            rgba(white.main, 0.8),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      > */}
      <Box component="section" className="contact-page" py={8}>
        <Container>
          <Grid container item xs={12} lg={12} sx={{ mx: "auto" }}>
            <Grid item xs={12} md={6}>
              <Box p={4} lineHeight={1}>
                <Typography mb={2} variant="h2" textGradient>
                  Contact Details
                </Typography>
                <Typography variant="body2" mt={1} mb={2}>
                  We provide professional elevator repair services to ensure
                  safe and reliable operation.
                </Typography>
                <Box mt={4}>
                  <Grid container spacing={2} alignItems="center">
                    <Grid item>
                      <LocationOnOutlined />
                    </Grid>
                    <Grid item>
                      <Typography variant="body1">
                        123 Main Street City, State, 12345
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
                <Box mt={4}>
                  <Grid container spacing={2} alignItems="center">
                    <Grid item>
                      <Phone />
                    </Grid>
                    <Grid item>
                      <Typography variant="body1">(123) 456-7890</Typography>
                    </Grid>
                  </Grid>
                </Box>
                <Box mt={4}>
                  <Grid container spacing={2} alignItems="center">
                    <Grid item>
                      <Email />
                    </Grid>
                    <Grid item>
                      <Typography variant="body1">
                        contact@example.com
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box p={4} lineHeight={1} className="contact-form">
                <form noValidate autoComplete="off">
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <SoftBox mb={2}>
                        <SoftBox mb={1} ml={0.5}>
                          <SoftTypography
                            component="label"
                            variant="caption"
                            fontWeight="bold"
                          >
                            Full Name
                          </SoftTypography>
                        </SoftBox>
                        <TextField
                          fullWidth
                          type="text"
                          name="name"
                          placeholder="Full Name"
                          variant="outlined"
                        />
                      </SoftBox>
                      <SoftBox mb={2}>
                        <SoftBox mb={1} ml={0.5}>
                          <SoftTypography
                            component="label"
                            variant="caption"
                            fontWeight="bold"
                          >
                            Email
                          </SoftTypography>
                        </SoftBox>
                        <TextField
                          fullWidth
                          type="email"
                          name="email"
                          placeholder="Email"
                          variant="outlined"
                        />
                      </SoftBox>
                      <SoftBox mb={2}>
                        <SoftBox mb={1} ml={0.5}>
                          <SoftTypography
                            component="label"
                            variant="caption"
                            fontWeight="bold"
                          >
                            Phone Number
                          </SoftTypography>
                        </SoftBox>
                        <TextField
                          fullWidth
                          type="text"
                          name="number"
                          placeholder="Phone"
                          variant="outlined"
                        />
                      </SoftBox>
                      <SoftBox mb={2}>
                        <SoftBox mb={1} ml={0.5}>
                          <SoftTypography
                            component="label"
                            variant="caption"
                            fontWeight="bold"
                          >
                            Message
                          </SoftTypography>
                        </SoftBox>
                        <textarea
                          fullWidth
                          type="email"
                          name="email"
                          placeholder="Message"
                          variant="outlined"
                        />
                      </SoftBox>
                      <SoftBox mt={4} mb={1}>
                        <SoftButton
                          className="success-btn"
                          variant="gradient"
                          color="success"
                          fullWidth
                        >
                          Submit
                        </SoftButton>
                      </SoftBox>
                    </Grid>
                  </Grid>
                </form>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Box component="section" className="contact-map">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3151.8354345093744!2d144.9537363155047!3d-37.81720974202133!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6ad642af0f11fd81%3A0xf5770f08a103f4db!2s123%20Main%20St%2C%20Melbourne%20VIC%203000%2C%20Australia!5e0!3m2!1sen!2sus!4v1614766960821!5m2!1sen!2sus"
          width="100%"
          height="300"
          style={{ border: 0 }}
          allowFullScreen=""
          loading="lazy"
          title="Google Maps"
        ></iframe>
      </Box>

      {/* </Card> */}
      <Footer />
    </PageLayout>
  );
};
export default Contact;
