import React from "react";
import { Formik, Form } from "formik";
import {
  TextField,
  Button,
  Box,
  Grid,
  FormControl,
  Autocomplete,
  Modal,
  Typography,
} from "@mui/material";
import axios from "../../api";
import SoftTypography from "components/SoftTypography";
import * as Yup from "yup";
import Locations from "examples/ReuseFunctions/Locations";

const customScrollbarStyle = {
  "&::-webkit-scrollbar": {
    width: "8px",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "#44444461", // Lighter black color
    borderRadius: "8px", // Curved border
  },
  "&::-webkit-scrollbar-thumb:hover": {
    backgroundColor: "#666", // Lighter black color on hover
  },
};

const validationSchema = Yup.object({
  state_id: Yup.string().required("State is required"),
  city_id: Yup.string().required("City is required"),
  region_id: Yup.string().required("Region is required"),
  address_type: Yup.string().required("Address type is required"),
  address: Yup.string().required("Address is required"),
});

const AddAddress = ({
  fetchSuccess,
  fetchError,
  isOpen,
  onClose,
  customerId,
}) => {
  const { states, cities, areas, setSelectedState, setSelectedCity } =
    Locations();
  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      aria-labelledby="otp-modal"
      aria-describedby="otp-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          bgcolor: "background.paper",
          borderRadius: "8px",
          boxShadow: 24,
          p: 4,
          minWidth: 500,
          minHeight: 220,
          maxHeight: "calc(100vh - 100px)",
          overflow: "auto",
          ...customScrollbarStyle,
        }}
      >
        <Typography variant="h5" style={{ marginBottom: "18px" }}>
          Add Address
        </Typography>
        <Formik
          initialValues={{
            state_id: "",
            city_id: "",
            region_id: "",
            address_type: "",
            address: "",
          }}
          validationSchema={validationSchema}
          onSubmit={async (values) => {
            try {
              const response = await axios.post(`/address`, {
                ...values,
                customer_id: customerId,
              });
              fetchSuccess(response.data.message);
              onClose();
            } catch (error) {
              fetchError(error.response.data.error);
            }
          }}
        >
          {({ values, handleChange, setFieldValue, errors, touched }) => (
            <Form>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <SoftTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                    >
                      State
                    </SoftTypography>
                    <Autocomplete
                      id="state-autocomplete"
                      options={states || []}
                      getOptionLabel={(option) => option.state_name || ""}
                      value={
                        states?.find(
                          (state) => state.state_id === values.state_id
                        ) || null
                      }
                      onChange={(event, newValue) => {
                        setFieldValue("city_id", "");
                        setFieldValue("region_id", "");
                        setFieldValue(
                          "state_id",
                          newValue ? newValue.state_id : ""
                        );
                        setSelectedState(newValue ? newValue.state_id : "");
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          name="state_id"
                          variant="outlined"
                          error={!!errors.state_id && touched.state_id}
                          helperText={touched.state_id && errors.state_id}
                          fullWidth
                        />
                      )}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <SoftTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                    >
                      City
                    </SoftTypography>
                    <Autocomplete
                      id="city-autocomplete"
                      options={cities || []}
                      getOptionLabel={(option) => option.city_name || ""}
                      value={
                        cities?.find(
                          (city) => city.city_id === values.city_id
                        ) || null
                      }
                      onChange={(event, newValue) => {
                        setFieldValue("region_id", "");
                        setFieldValue(
                          "city_id",
                          newValue ? newValue.city_id : ""
                        );
                        setSelectedCity(newValue ? newValue.city_id : "");
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          name="city_id"
                          variant="outlined"
                          error={!!errors.city_id && touched.city_id}
                          helperText={touched.city_id && errors.city_id}
                          fullWidth
                        />
                      )}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <SoftTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                    >
                      Area
                    </SoftTypography>
                    <Autocomplete
                      id="region-autocomplete"
                      options={areas || []}
                      getOptionLabel={(option) => option.region_name || ""}
                      value={
                        areas?.find(
                          (city) => city.region_id === values.region_id
                        ) || null
                      }
                      onChange={(event, newValue) => {
                        setFieldValue(
                          "region_id",
                          newValue ? newValue.region_id : ""
                        );
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          name="region_id"
                          variant="outlined"
                          error={!!errors.region_id && touched.region_id}
                          helperText={touched.region_id && errors.region_id}
                          fullWidth
                        />
                      )}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <SoftTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                  >
                    Address Type
                  </SoftTypography>
                  <TextField
                    fullWidth
                    name="address_type"
                    value={values.address_type}
                    onChange={handleChange}
                    error={!!errors.address_type && touched.address_type}
                    helperText={touched.address_type && errors.address_type}
                  />
                </Grid>
                <Grid item xs={12}>
                  <SoftTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                  >
                    Address
                  </SoftTypography>
                  <TextField
                    fullWidth
                    name="address"
                    value={values.address}
                    onChange={handleChange}
                    error={!!errors.address && touched.address}
                    helperText={touched.address && errors.address}
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Button
                      className="success-btn"
                      sx={{
                        position: "relative",
                        minWidth: 100,
                      }}
                      variant="contained"
                      type="submit"
                      color="success"
                    >
                      Save
                    </Button>

                    <Button
                      className="cancel-btn"
                      sx={{
                        position: "relative",
                        minWidth: 100,
                        ml: 2.5,
                      }}
                      variant="contained"
                      color="warning"
                      onClick={onClose}
                      type="cancel"
                    >
                      Cancel
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Box>
    </Modal>
  );
};

export default AddAddress;
