import React, { useCallback, useEffect, useState } from "react";
import {
  Box,
  Card,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  Paper,
  Typography,
  IconButton,
  Modal,
} from "@mui/material";
import axios from "../../api";
import PageLoader from "examples/ReuseFunctions/PageLoader";
import { useParams } from "react-router-dom";
import { useSnackbar } from "components/AlertMessages/SnackbarContext";
import SoftBox from "components/SoftBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import { Edit as EditIcon } from "@mui/icons-material";
import Locations from "examples/ReuseFunctions/Locations";
import EditAddress from "./EditAddress";

const customScrollbarStyle = {
  "&::-webkit-scrollbar": {
    width: "8px",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "#44444461",
    borderRadius: "8px",
  },
  "&::-webkit-scrollbar-thumb:hover": {
    backgroundColor: "#666",
  },
};

const ViewAddresses = () => {
  const { fetchError, fetchSuccess } = useSnackbar();
  const { id } = useParams();
  const navigate = useNavigate();
  const { states, cities, areas, setSelectedState, setSelectedCity } =
    Locations();
  const [addressData, setAddressData] = useState(null);
  const [address, setAddress] = useState(null);
  const handlePrimaryAddress = async (event, addressId, id) => {
    await axios.put(`/address/is-primary/${addressId}`, {
      is_primary: event.target.value,
      customer_id: id,
    });
    fetchAddressData();
    fetchSuccess("Address selected as primary");
  };
  const fetchAddressData = useCallback(async () => {
    try {
      const response = await axios.get(`/address/customer/${id}`);
      const { data } = response;
      setAddressData(data?.list);
    } catch (error) {
      console.error(error.response.data.error);
    }
  }, [id]);

  useEffect(() => {
    fetchAddressData();
  }, [fetchAddressData]);
  const [isEditOpen, setIsEditOpen] = useState(false);

  const handleEditOpen = (data) => {
    setAddress(data);
    setIsEditOpen(true);
  };

  const handleEditClose = () => {
    setIsEditOpen(false);
  };
  if (!addressData) {
    return <PageLoader />;
  }
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Card
        sx={{
          maxWidth: "100vw",
          width: "100%",
          padding: "30px",
          maxHeight: "70%",
        }}
      >
        <Grid
          item
          xs={12}
          display={"flex"}
          justifyContent={"center"}
          className="view-ticket-title"
        >
          <IconButton className="back-btn">
            <ArrowBackIcon onClick={() => navigate("/customers-list")} />
          </IconButton>
          <Typography variant="h5" gutterBottom>
            Manage Addresses
          </Typography>
        </Grid>
        <SoftBox mt={5} md={12} lg={12}>
          <Grid justifyContent="center">
            <Grid item xs={12} md={8} lg={6}>
              {addressData.map((data) => (
                <Paper
                  style={{
                    padding: "20px",
                    marginBottom: "20px",
                    border: "1px solid #ccc",
                  }}
                >
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <FormGroup row>
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="primary"
                            checked={data?.is_primary || false}
                            onChange={(event) =>
                              handlePrimaryAddress(
                                event,
                                data?.address_id,
                                data?.customer_id
                              )
                            }
                          />
                        }
                        label="Primary"
                      />
                    </FormGroup>
                    <IconButton
                      aria-label="edit"
                      onClick={() => handleEditOpen(data)}
                    >
                      <EditIcon />
                    </IconButton>
                  </div>
                  <div>
                    <Grid container spacing={2}>
                      <Grid item xs={4}>
                        <Typography variant="h6">
                          <Box display="flex" justifyContent="left">
                            <span>State</span>
                          </Box>
                        </Typography>
                      </Grid>
                      <Grid item xs={1}>
                        <Typography variant="h6">
                          <Box display="flex" justifyContent="center">
                            <span>:</span>
                          </Box>
                        </Typography>
                      </Grid>
                      <Grid item xs={7}>
                        <Typography variant="h6" sx={{ fontWeight: "normal" }}>
                          <Box display="flex" justifyContent="flex-start">
                            <span>{data.state_name}</span>
                          </Box>
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item xs={4}>
                        <Typography variant="h6">
                          <Box display="flex" justifyContent="left">
                            <span>City</span>
                          </Box>
                        </Typography>
                      </Grid>
                      <Grid item xs={1}>
                        <Typography variant="h6">
                          <Box display="flex" justifyContent="center">
                            <span>:</span>
                          </Box>
                        </Typography>
                      </Grid>
                      <Grid item xs={7}>
                        <Typography variant="h6" sx={{ fontWeight: "normal" }}>
                          <Box display="flex" justifyContent="flex-start">
                            <span>{data.city_name}</span>
                          </Box>
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item xs={4}>
                        <Typography variant="h6">
                          <Box display="flex" justifyContent="left">
                            <span>Region</span>
                          </Box>
                        </Typography>
                      </Grid>
                      <Grid item xs={1}>
                        <Typography variant="h6">
                          <Box display="flex" justifyContent="center">
                            <span>:</span>
                          </Box>
                        </Typography>
                      </Grid>
                      <Grid item xs={7}>
                        <Typography variant="h6" sx={{ fontWeight: "normal" }}>
                          <Box display="flex" justifyContent="flex-start">
                            <span>{data.region_name}</span>
                          </Box>
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item xs={4}>
                        <Typography variant="h6">
                          <Box display="flex" justifyContent="left">
                            <span>Address- Type</span>
                          </Box>
                        </Typography>
                      </Grid>
                      <Grid item xs={1}>
                        <Typography variant="h6">
                          <Box display="flex" justifyContent="center">
                            <span>:</span>
                          </Box>
                        </Typography>
                      </Grid>
                      <Grid item xs={7}>
                        <Typography variant="h6" sx={{ fontWeight: "normal" }}>
                          <Box display="flex" justifyContent="flex-start">
                            <span>{data.address_type}</span>
                          </Box>
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item xs={4}>
                        <Typography variant="h6">
                          <Box display="flex" justifyContent="left">
                            <span>Address</span>
                          </Box>
                        </Typography>
                      </Grid>
                      <Grid item xs={1}>
                        <Typography variant="h6">
                          <Box display="flex" justifyContent="center">
                            <span>:</span>
                          </Box>
                        </Typography>
                      </Grid>
                      <Grid item xs={7}>
                        <Typography variant="h6" sx={{ fontWeight: "normal" }}>
                          <Box display="flex" justifyContent="flex-start">
                            <span>{data.address}</span>
                          </Box>
                        </Typography>
                      </Grid>
                    </Grid>
                  </div>
                </Paper>
              ))}
            </Grid>
          </Grid>
        </SoftBox>
        <Modal open={isEditOpen} onClose={handleEditClose}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              bgcolor: "background.paper",
              borderRadius: "8px",
              boxShadow: 24,
              p: 4,
              maxWidth: 500,
              maxHeight: "calc(100vh - 100px)",
              overflow: "auto",
              ...customScrollbarStyle,
            }}
          >
            <EditAddress
              data={address}
              states={states}
              cities={cities}
              areas={areas}
              setSelectedState={setSelectedState}
              setSelectedCity={setSelectedCity}
              fetchSuccess={fetchSuccess}
              fetchError={fetchError}
              fetchAddressData={fetchAddressData}
              onClose={handleEditClose}
              setIsEditOpen={setIsEditOpen}
            />
          </Box>
        </Modal>
      </Card>
    </DashboardLayout>
  );
};

export default ViewAddresses;
