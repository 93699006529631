import * as React from "react";
import { useNavigate } from "react-router-dom";
import MUIDataTable from "mui-datatables";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import {
  Autocomplete,
  Box,
  Button,
  Card,
  Grid,
  IconButton,
  TextField,
  Tooltip,
  FormControl,
} from "@mui/material";
import { Edit as EditIcon } from "@mui/icons-material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import axios from "../../api";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import SoftBox from "components/SoftBox";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useLocation } from "react-router-dom";
import AssignTicket from "./AssignTicket";
import SoftTypography from "components/SoftTypography";
import StarIcon from "@mui/icons-material/Star";
import { useSnackbar } from "components/AlertMessages/SnackbarContext";

const getMuiTheme = (theme) =>
  createTheme({
    components: {
      MUIDataTable: {
        styleOverrides: {
          responsiveScroll: {
            maxHeight: "unset",
            overflowX: "auto",
          },
          responsiveScrollFullHeight: {
            maxHeight: "unset",
            overflowX: "auto",
          },
          responsiveScrollMaxHeight: {
            maxHeight: "unset",
            overflowX: "auto",
          },
        },
      },
      MUIDataTableHeadCell: {
        styleOverrides: {
          root: {
            backgroundColor: theme.palette.background.default,
          },
        },
      },
    },
  });

const TicketList = () => {
  const navigate = useNavigate();
  const { fetchError, fetchSuccess } = useSnackbar();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const status = queryParams.get("status_id");
  const [rows, setRows] = React.useState([]);
  const [isOpen, setOpen] = React.useState(false);
  const [selectedTicket, setSelectedTicket] = React.useState(null);
  const [ticketStatuses, setTicketStatuses] = React.useState(null);
  const [statusFilter, setStatusFilter] = React.useState(null);
  const [customFilter, setCustomFilter] = React.useState(null);
  const [dateFilter, setDateFilter] = React.useState(null);

  const options = {
    selectableRows: "none",
    selectableRowsHeader: false,
    elevation: 0,
    setRowProps: (row, dataIndex) => {
      const isEscalated = rows[dataIndex]?.is_escalated;
      return {
        style: {
          backgroundColor: isEscalated ? "#ffaba7" : null,
        },
      };
    },
  };
  const handleAssignTicketOpen = (ticketData) => {
    setSelectedTicket(ticketData);
    setOpen(true);
  };

  const handleAssignTicketClose = () => {
    setOpen(false);
  };
  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "short", day: "numeric" };
    const date = new Date(dateString);
    return new Intl.DateTimeFormat("en-US", options).format(date);
  };
  const fetchData = React.useCallback(async () => {
    try {
      const response = await axios.get("/tickets", {
        params: {
          status_id: statusFilter ? statusFilter : status,
          filters: {
            customFilter: customFilter,
            dateFilter: dateFilter,
          },
        },
      });
      setRows(response?.data?.list);
    } catch (error) {
      console.error("Error: ", error);
    }
  }, [statusFilter, status, customFilter, dateFilter]);

  React.useEffect(() => {
    fetchData();
  }, [fetchData, statusFilter, customFilter, dateFilter]);

  const fetchStatus = async () => {
    try {
      const response = await axios.get("/ticket-statuses");
      setTicketStatuses(response?.data);
    } catch (error) {
      console.error("Error: ", error);
    }
  };
  React.useEffect(() => {
    fetchStatus();
  }, []);

  const handleDeEscalationUpdate = async (ticket) => {
    const ticketData = {
      is_escalated: 0,
    };
    try {
      await axios.put(`/tickets/escalation/${ticket?.ticket_id}`, ticketData);
      fetchData();
      fetchSuccess("Ticket de-escalated Successfully");
    } catch (err) {
      console.error("Error: ", err);
      fetchError("Unable to de-escalate Ticket");
    }
  };

  const columns = [
    { name: "ticket_service_id", label: "Service Id" },
    {
      name: "customer_name",
      label: "Customer Name",
      options: {
        filter: false,
        customBodyRender: (value, tableMeta) => {
          const { currentTableData, rowIndex } = tableMeta;
          const data = tableMeta?.tableData[currentTableData[rowIndex]?.index];
          if (data?.premium_customer) {
            return (
              <span className="subscription">
                {data.customer_name}
                <StarIcon />
              </span>
            );
          }
          return value;
        },
      },
    },

    {
      name: "address",
      label: "Address",
      options: {
        filter: false,
        customBodyRender: (value, tableMeta) => {
          const { currentTableData, rowIndex } = tableMeta;
          const data = tableMeta?.tableData[currentTableData[rowIndex]?.index];
          const city = data?.city_name;
          const state = data?.state_name;
          const addressType = data?.address_type;
          const addressData = `${value}, ${city}, ${state},${addressType}`;

          const displayValue =
            addressData?.length > 20
              ? `${addressData.substring(0, 20)}...`
              : addressData;
          return (
            <Tooltip title={addressData}>
              <span>{displayValue}</span>
            </Tooltip>
          );
        },
      },
    },
    {
      name: "employee_name",
      label: "Assignee",
      options: {
        filter: false,
        customBodyRender: (value) => {
          return value ? value : "Unassigned";
        },
      },
    },
    {
      name: "created_at",
      label: "Created At",
      options: {
        filter: false,
        customBodyRender: (value) => formatDate(value),
      },
    },
    { name: "status_name", label: "Status" },
    {
      name: "id",
      label: "Actions",
      align: "center",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => {
          const { currentTableData, rowIndex } = tableMeta;
          const ticketData =
            tableMeta?.tableData[currentTableData[rowIndex]?.index];
          return (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <IconButton
                aria-label="edit"
                onClick={() =>
                  navigate(
                    `/admin/edit-ticket/${ticketData.ticket_id}?regionId=${ticketData.region_id}`
                  )
                }
              >
                <EditIcon />
              </IconButton>
              <IconButton
                aria-label="edit"
                onClick={() =>
                  navigate(`/admin/ticket-view/${ticketData.ticket_id}`)
                }
              >
                <VisibilityIcon />
              </IconButton>
              <Button
                className="assign-btn"
                sx={{
                  position: "relative",
                }}
                size="small"
                variant="contained"
                onClick={() => {
                  handleAssignTicketOpen(ticketData);
                }}
              >
                {ticketData?.assigned_employee_id ? "Re-Assign" : "Assign"}
              </Button>
              {ticketData?.is_escalated ? (
                <Button
                  className="assign-btn"
                  sx={{
                    position: "relative",
                    marginLeft: "5px",
                  }}
                  size="small"
                  variant="contained"
                  onClick={() => {
                    handleDeEscalationUpdate(ticketData);
                  }}
                >
                  De-Escalate
                </Button>
              ) : null}
            </Box>
          );
        },
      },
    },
  ];

  const filterOptions = ["Week", "Month", "Year"];
  let date = new Date();
  switch (customFilter) {
    case "Week":
      date.setDate(date.getDate() - 7);
      break;
    case "Month":
      date.setDate(date.getDate() - 30);
      break;
    case "Year":
      date.setDate(date.getDate() - 365);
      break;
    default:
      date = null;
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Card
        style={{ padding: "24px", margin: "16px auto", maxWidth: "2000px" }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "20px",
          }}
          className="manage-tickets-header"
        >
          <Grid className="dpflex">
            <Grid className="dpblock">
              <FormControl fullWidth>
                <SoftTypography
                  component="label"
                  variant="caption"
                  fontWeight="bold"
                >
                  Customised Date Filter
                </SoftTypography>
                <Autocomplete
                  options={filterOptions || []}
                  getOptionLabel={(option) => option}
                  onChange={(event, value) => setCustomFilter(value)}
                  renderInput={(params) => (
                    <TextField {...params} variant="outlined" />
                  )}
                />
              </FormControl>
              {date && (
                <h6>
                  From:{" "}
                  <span>
                    {date.toISOString().slice(0, 19).replace("T", " ")}
                  </span>{" "}
                  To:{" "}
                  <span>
                    {new Date().toISOString().slice(0, 19).replace("T", " ")}
                  </span>
                </h6>
              )}
            </Grid>
            <FormControl fullWidth sx={{ paddingLeft: "10px" }}>
              <SoftTypography
                component="label"
                variant="caption"
                fontWeight="bold"
                sx={{ paddingLeft: "10px" }}
              >
                Created Date
              </SoftTypography>

              <TextField
                className="date-field"
                name="start_date"
                type="date"
                fullWidth
                variant="outlined"
                onChange={(event) => setDateFilter(event.target.value)}
                inputProps={{
                  max: new Date().toISOString().split("T")[0],
                }}
              />
            </FormControl>
            <FormControl fullWidth sx={{ paddingLeft: "10px" }}>
              <SoftTypography
                component="label"
                variant="caption"
                fontWeight="bold"
                sx={{ paddingLeft: "10px" }}
              >
                Status
              </SoftTypography>
              <Autocomplete
                options={ticketStatuses || []}
                getOptionLabel={(option) => option.status_name}
                onChange={(event, value) => {
                  // fetchData(value?.status_id || null);
                  setStatusFilter(value?.status_id || null);
                }}
                renderInput={(params) => (
                  <TextField {...params} variant="outlined" />
                )}
              />
            </FormControl>
          </Grid>
          <Grid>
            <Button
              className="success-btn"
              variant="gradient"
              color="success"
              onClick={() => navigate(`/admin/add-ticket`)}
            >
              Add Ticket
            </Button>
          </Grid>
        </div>

        <SoftBox
          sx={{
            "& .MuiTableRow-root:not(:last-child)": {
              "& td": {
                borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                  `${borderWidth[1]} solid ${borderColor}`,
              },
            },
          }}
        >
          <ThemeProvider theme={getMuiTheme}>
            <MUIDataTable
              className="manage-tickets"
              title={"Manage Tickets"}
              data={rows}
              columns={columns}
              options={options}
            />
          </ThemeProvider>
        </SoftBox>
      </Card>
      {isOpen && (
        <AssignTicket
          assignTicketData={selectedTicket}
          isOpen={isOpen}
          onClose={handleAssignTicketClose}
          fetchData={fetchData}
        />
      )}
    </DashboardLayout>
  );
};

export default TicketList;
