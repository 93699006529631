import React, { useEffect, useState } from "react";
import axios from "../../api";
import {
  Button,
  TextField,
  Grid,
  Box,
  Autocomplete,
  Modal,
} from "@mui/material";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import Typography from "@mui/material/Typography";
import { useSnackbar } from "components/AlertMessages/SnackbarContext";
import SoftTypography from "components/SoftTypography";
import SoftBox from "components/SoftBox";
import AWS from "aws-sdk";
import { useDropzone } from "react-dropzone";
import LiftNotes from "examples/ReuseFunctions/LiftNotes";
import { useSelector } from "react-redux";

const customScrollbarStyle = {
  "&::-webkit-scrollbar": {
    width: "8px",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "#44444461", // Lighter black color
    borderRadius: "8px", // Curved border
  },
  "&::-webkit-scrollbar-thumb:hover": {
    backgroundColor: "#666", // Lighter black color on hover
  },
};

const s3 = new AWS.S3({
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
  region: process.env.REACT_APP_AWS_REGION,
});

const validationSchema = Yup.object({
  address_id: Yup.string().required("Address is required"),
  customer_id: Yup.string().required("Customer is required"),
  lift_name: Yup.string().required("Lift name is required"),
  lift_installed_date: Yup.string()
    .matches(
      /^\d{4}-\d{2}-\d{2}$/,
      "Installation date must be in the format YYYY-MM-DD"
    )
    .required("Lift installation date is required")
    .test("is-valid-date", "Installation date is not a valid date", (value) => {
      return !isNaN(new Date(value).getTime());
    }),
  lift_registartion_id: Yup.string().required("Lift register id is required"),
});

const AddLift = ({ isOpen, onClose, customers, fetchData }) => {
  const user = useSelector((state) => state.auth.user);
  const { fetchError, fetchSuccess } = useSnackbar();
  const [addresses, setAddresses] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [liftNote, setLiftNote] = useState(null);
  const today = new Date();
  const formattedToday = today.toISOString().split("T")[0];

  const dropzone = useDropzone({
    accept: {
      "image/png": [".png", ".jpeg", ".jpg"],
      "video/mp4": [".mp4"],
    },
    onDrop: (acceptedFiles) => {
      // Append new files to the existing uploadedFiles array
      setUploadedFiles((prevFiles) => [
        ...prevFiles,
        ...acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        ),
      ]);
    },
  });

  useEffect(() => {
    setUploadedFiles(dropzone.acceptedFiles);
  }, [dropzone.acceptedFiles]);

  useEffect(() => {
    const fetchAddressData = async () => {
      try {
        const categoriesResponse = await axios.get(
          `/address/customer/${selectedCustomer}`
        );
        const { data } = categoriesResponse;
        setAddresses(data.list);
      } catch (err) {
        console.error("Error fetching data", err);
      }
    };

    fetchAddressData();
  }, [selectedCustomer]);
  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      aria-labelledby="otp-modal"
      aria-describedby="otp-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          bgcolor: "background.paper",
          borderRadius: "8px",
          boxShadow: 24,
          p: 4,
          minWidth: 500,
          minHeight: 220,
          maxHeight: "calc(100vh - 100px)",
          overflow: "auto",
          ...customScrollbarStyle,
        }}
      >
        <Typography variant="h5" style={{ marginBottom: "18px" }}>
          Add New Lift
        </Typography>
        <Formik
          initialValues={{
            address_id: "",
            lift_name: "",
            customer_id: "",
            lift_installed_date: "",
            lift_registartion_id: "",
            lifts_note: null,
          }}
          validationSchema={validationSchema}
          onSubmit={async (values) => {
            setSubmitting(true);
            if (liftNote) {
              const liftNotesdata = LiftNotes(null, user?.name, liftNote);
              values.lifts_note = liftNotesdata;
            }
            try {
              if (uploadedFiles && uploadedFiles.length > 0) {
                const file = uploadedFiles[0];
                const fileName = `${Date.now()}-${file.name}`;
                values.file_name = fileName;
                const params = {
                  Bucket: process.env.REACT_APP_AWS_BUCKET_NAME,
                  Key: fileName,
                  Body: file,
                  ContentType: file.type,
                };
                let type = file.type.split("/")[0];
                if (type === "image") {
                  type = "Photo";
                }
                values.file_type = type;
                s3.upload(params, async (err, data) => {
                  if (err) {
                    console.error("Error uploading file:", err);
                    setSubmitting(false);
                  } else {
                    try {
                      const response = await axios.post("/lifts", values);
                      fetchData();
                      fetchSuccess(response.data.message);
                      onClose();
                    } catch (error) {
                      fetchError(error.response.data.error);
                    } finally {
                      setSubmitting(false);
                    }
                  }
                });
              } else {
                try {
                  const response = await axios.post("/lifts", values);
                  fetchData();
                  fetchSuccess(response.data.message);
                  onClose();
                  setSubmitting(false);
                } catch (error) {
                  fetchError(error.response.data.error);
                } finally {
                  setSubmitting(false);
                }
              }
            } catch (error) {
              fetchError(error.response.data.error);
              setSubmitting(false);
            }
          }}
        >
          {({ errors, touched, setFieldValue }) => (
            <Form
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "20px",
              }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <SoftBox>
                    <SoftTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                    >
                      Customer
                    </SoftTypography>
                    <Autocomplete
                      options={customers || []}
                      getOptionLabel={(option) =>
                        `${option.name} - (${option.phone})`
                      }
                      onChange={(event, value) => {
                        setSelectedAddress(null);
                        setFieldValue("address_id", "");
                        setFieldValue("customer_id", value?.customer_id || "");
                        setSelectedCustomer(value?.customer_id);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          error={!!errors.customer_id && touched.customer_id}
                          helperText={touched.customer_id && errors.customer_id}
                        />
                      )}
                    />
                  </SoftBox>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <SoftBox>
                    <SoftTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                    >
                      Lift Name
                    </SoftTypography>
                    <Field
                      name="lift_name"
                      as={TextField}
                      fullWidth
                      variant="outlined"
                      error={!!errors.lift_name && touched.lift_name}
                      helperText={touched.lift_name && errors.lift_name}
                    />
                  </SoftBox>
                </Grid>
                <Grid item xs={12}>
                  <SoftBox>
                    <SoftTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                    >
                      Address
                    </SoftTypography>
                    <Autocomplete
                      options={addresses || []}
                      value={selectedAddress}
                      getOptionLabel={(option) =>
                        `${option.address},${option.address_type}-${option.region_name}-${option.city_name}-${option.state_name}`
                      }
                      onChange={(event, value) => {
                        setFieldValue("address_id", value?.address_id || "");
                        setSelectedAddress(value);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          error={!!errors.address_id && touched.address_id}
                          helperText={touched.address_id && errors.address_id}
                        />
                      )}
                    />
                  </SoftBox>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <SoftBox>
                    <SoftTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                    >
                      Registeration Id
                    </SoftTypography>
                    <Field
                      name="lift_registartion_id"
                      as={TextField}
                      fullWidth
                      variant="outlined"
                      error={
                        errors.lift_registartion_id &&
                        touched.lift_registartion_id
                      }
                      helperText={
                        touched.lift_registartion_id &&
                        errors.lift_registartion_id
                      }
                    />
                  </SoftBox>
                </Grid>
                <Grid item xs={12} sm={6}>
                  {/* <FormControl fullWidth> */}
                  <SoftTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                  >
                    Lift Installation Date
                  </SoftTypography>
                  <Field
                    className="date-field"
                    name="lift_installed_date"
                    as={TextField}
                    type="date"
                    fullWidth
                    variant="outlined"
                    InputProps={{
                      inputProps: {
                        max: formattedToday,
                      },
                    }}
                    error={
                      errors.lift_installed_date && touched.lift_installed_date
                    }
                    helperText={
                      touched.lift_installed_date && errors.lift_installed_date
                    }
                  />
                  {/* </FormControl> */}
                </Grid>
                <Grid item xs={12}>
                  <SoftTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                  >
                    Lift Notes
                  </SoftTypography>
                  <TextField
                    variant="outlined"
                    fullWidth
                    multiline
                    rows={4}
                    value={liftNote}
                    onChange={(event) => {
                      const data = event.target.value;
                      setFieldValue("lifts_note", data);
                      setLiftNote(data === "" ? null : data);
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  {uploadedFiles.length > 0 && (
                    <div className="upload-media">
                      {uploadedFiles[0]?.preview && (
                        <div
                          className="media-container"
                          style={{ position: "relative" }}
                        >
                          {uploadedFiles[0]?.type.startsWith("image") ? (
                            <img
                              src={uploadedFiles[0]?.preview}
                              alt="media"
                              className="upload-img"
                            />
                          ) : (
                            <video
                              className="upload-video"
                              controls
                              width="100%"
                              height="140px"
                            >
                              <source
                                src={uploadedFiles[0]?.preview}
                                type="video/mp4"
                              />
                            </video>
                          )}
                        </div>
                      )}
                    </div>
                  )}

                  <Box
                    sx={{ cursor: "pointer", width: "fit-content" }}
                    {...dropzone.getRootProps()}
                  >
                    <Box>
                      <label htmlFor="image-upload-input">
                        <input
                          name="file_name"
                          {...dropzone.getInputProps({
                            name: "file_name",
                          })}
                        />
                        <Button
                          component="span"
                          variant="contained"
                          color="primary"
                          className="upload-btn"
                        >
                          Upload File
                        </Button>
                      </label>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
              <Grid item xs={12} md={12}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Button
                    className="success-btn"
                    sx={{
                      position: "relative",
                      minWidth: 100,
                    }}
                    variant="contained"
                    disabled={submitting}
                    type="submit"
                    color="success"
                  >
                    Save
                  </Button>
                  <Button
                    className="cancel-btn"
                    sx={{
                      position: "relative",
                      minWidth: 100,
                      ml: 2.5,
                    }}
                    variant="contained"
                    color="warning"
                    onClick={onClose}
                    type="button"
                  >
                    Cancel
                  </Button>
                </Box>
              </Grid>
            </Form>
          )}
        </Formik>
      </Box>
    </Modal>
  );
};

export default AddLift;
