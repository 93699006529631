import { Box, Typography } from "@mui/material";

const FormatAssignHistoryData = (assignedData) => {
  const data = JSON.parse(assignedData);
  return data?.map((item, index) => (
    <Box key={index} mb={2} className="assignee-history">
      <Typography variant="body2">
        Assigned to : <span>{item["Assigned to"]}</span> on{" "}
        <span>{item["Date"]}</span>
      </Typography>
    </Box>
  ));
};
export default FormatAssignHistoryData;
