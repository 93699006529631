import React, { useEffect, useState } from "react";
import { Formik, Form } from "formik";
import {
  TextField,
  Button,
  Box,
  Grid,
  FormControl,
  Autocomplete,
} from "@mui/material";
import axios from "../../api";
import Locations from "examples/ReuseFunctions/Locations";
import SoftTypography from "components/SoftTypography";
import * as Yup from "yup";

const validationSchema = Yup.object({
  state_id: Yup.string().required("State is required"),
  city_id: Yup.string().required("City is required"),
  region_id: Yup.string().required("Region is required"),
  address_type: Yup.string().required("Address type is required"),
  address: Yup.string().required("Address is required"),
});

const EditAddress = ({
  data,
  fetchSuccess,
  fetchError,
  fetchAddressData,
  onClose,
}) => {
  const [initialState, setInitialState] = useState({
    state_id: data?.state_id,
    state_name: data?.state_name,
  });
  const [initialCity, setInitialCity] = useState({
    city_id: data?.city_id,
    city_name: data?.city_name,
  });
  const [initialRegion, setInitialRegion] = useState({
    region_id: data?.region_id,
    region_name: data?.region_name,
  });
  const { states, cities, areas, setSelectedState, setSelectedCity } =
    Locations();
  useEffect(() => {
    if (data) {
      setSelectedState(data.state_id);
      setSelectedCity(data.city_id);
    }
  }, [data, setSelectedState, setSelectedCity]);
  return (
    <Formik
      initialValues={{
        state_id: data?.state_id || "",
        city_id: data?.city_id || "",
        region_id: data?.region_id || "",
        address_type: data?.address_type || "",
        address: data?.address || "",
      }}
      validationSchema={validationSchema}
      onSubmit={async (values) => {
        try {
          const response = await axios.put(
            `/address/${data?.address_id}`,
            values
          );
          fetchSuccess(response.data.message);
          fetchAddressData();
          onClose();
        } catch (error) {
          fetchError(error.response.data.error);
        }
      }}
    >
      {({ values, handleChange, errors, touched, setFieldValue }) => (
        <Form className="update-address-popup">
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <SoftTypography
                  component="label"
                  variant="caption"
                  fontWeight="bold"
                >
                  State
                </SoftTypography>
                <Autocomplete
                  id="state-autocomplete"
                  options={states || []}
                  getOptionLabel={(option) => option.state_name || ""}
                  value={values.state_id ? initialState : null}
                  onChange={(event, newValue) => {
                    setFieldValue("city_id", "");
                    setFieldValue("region_id", "");
                    setFieldValue(
                      "state_id",
                      newValue ? newValue.state_id : ""
                    );
                    setInitialState({
                      state_id: newValue?.state_id || null,
                      state_name: newValue?.state_name || null,
                    });
                    setSelectedState(newValue ? newValue.state_id : "");
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name="state_id"
                      variant="outlined"
                      error={!!errors.state_id && touched.state_id}
                      helperText={touched.state_id && errors.state_id}
                      fullWidth
                    />
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth>
                <SoftTypography
                  component="label"
                  variant="caption"
                  fontWeight="bold"
                >
                  City
                </SoftTypography>
                <Autocomplete
                  id="city-autocomplete"
                  options={cities || []}
                  getOptionLabel={(option) => option.city_name || ""}
                  value={values.city_id ? initialCity : null}
                  onChange={(event, newValue) => {
                    setFieldValue("region_id", "");
                    setFieldValue("city_id", newValue ? newValue.city_id : "");
                    setInitialCity({
                      city_id: newValue?.city_id || null,
                      city_name: newValue?.city_name || null,
                    });
                    setSelectedCity(newValue ? newValue.city_id : "");
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name="city_id"
                      variant="outlined"
                      error={!!errors.city_id && touched.city_id}
                      helperText={touched.city_id && errors.city_id}
                      fullWidth
                    />
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <SoftTypography
                  component="label"
                  variant="caption"
                  fontWeight="bold"
                >
                  Area
                </SoftTypography>
                <Autocomplete
                  id="region-autocomplete"
                  options={areas || []}
                  getOptionLabel={(option) => option.region_name || ""}
                  value={values.region_id ? initialRegion : null}
                  onChange={(event, newValue) => {
                    setFieldValue(
                      "region_id",
                      newValue ? newValue.region_id : ""
                    );
                    setInitialRegion({
                      region_id: newValue?.region_id || null,
                      region_name: newValue?.region_name || null,
                    });
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name="region_id"
                      variant="outlined"
                      error={!!errors.region_id && touched.region_id}
                      helperText={touched.region_id && errors.region_id}
                      fullWidth
                    />
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <SoftTypography
                component="label"
                variant="caption"
                fontWeight="bold"
              >
                Address Type
              </SoftTypography>
              <TextField
                fullWidth
                placeholder="Address Type"
                name="address_type"
                value={values.address_type}
                onChange={handleChange}
                error={!!errors.address_type && touched.address_type}
                helperText={touched.address_type && errors.address_type}
              />
            </Grid>
            <Grid item xs={12}>
              <SoftTypography
                component="label"
                variant="caption"
                fontWeight="bold"
              >
                Address
              </SoftTypography>
              <TextField
                fullWidth
                placeholder="Address"
                name="address"
                value={values.address}
                onChange={handleChange}
                error={!!errors.address && touched.address}
                helperText={touched.address && errors.address}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Button
                  className="success-btn"
                  sx={{
                    position: "relative",
                    minWidth: 100,
                  }}
                  variant="contained"
                  type="submit"
                  color="success"
                >
                  Update
                </Button>

                <Button
                  className="cancel-btn"
                  sx={{
                    position: "relative",
                    minWidth: 100,
                    ml: 2.5,
                  }}
                  variant="contained"
                  color="warning"
                  onClick={onClose}
                  type="cancel"
                >
                  Cancel
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

export default EditAddress;
