import * as React from "react";
import { useNavigate } from "react-router-dom";
import MUIDataTable from "mui-datatables";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Box, Card, Switch, IconButton, Button } from "@mui/material";
import { Edit as EditIcon } from "@mui/icons-material";
import axios from "../../api";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import SoftBox from "components/SoftBox";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import SoftButton from "components/SoftButton";
import AddSubscriptionModal from "./AddSubscriptionModal";
import { useSnackbar } from "components/AlertMessages/SnackbarContext";
import VisibilityIcon from "@mui/icons-material/Visibility";
import AddAddress from "./AddAddress";
import { useSelector } from "react-redux";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";

const getMuiTheme = (theme) =>
  createTheme({
    components: {
      MUIDataTableHeadCell: {
        styleOverrides: {
          root: {
            backgroundColor: theme.palette.background.default,
          },
        },
      },
    },
  });

const options = {
  selectableRows: "none", // "none" will hide checkboxes on rows
  selectableRowsHeader: false, // Set to false to hide the header checkbox
  elevation: 0,
};

const CustomerList = () => {
  const { fetchError, fetchSuccess } = useSnackbar();
  const user = useSelector((state) => state.auth.user);
  const navigate = useNavigate();
  const [rows, setRows] = React.useState([]);
  const [modalOpen, setModalOpen] = React.useState(false);
  const [addressOpen, setAddressOpen] = React.useState(false);
  const [customerId, setCustomerId] = React.useState(null);
  const handleOpen = () => {
    setModalOpen(true);
  };

  const handleClose = () => {
    setModalOpen(false);
  };
  const handleAddressOpen = (data) => {
    setCustomerId(data);
    setAddressOpen(true);
  };

  const handleAddressClose = () => {
    setAddressOpen(false);
  };

  const handleSwap = async (userMail) => {
    const swapData = {
      actualEmail: user.email,
      actualRole: user.Role[0],
      actualRoleId: user.roleId,
      actualName: user.name,
      actualUserId: user.userId,
      userRole: 5,
      userMail: userMail,
    };
    try {
      await axios.post("/auth/switch-user", swapData);
      window.location.reload();
    } catch (error) {
      console.error(error);
    }
  };
  const columns = [
    {
      name: "name",
      label: "Name",
      options: {
        filter: false,
        customBodyRender: (value, tableMeta) => {
          const { currentTableData, rowIndex } = tableMeta;
          const customerData =
            tableMeta?.tableData[currentTableData[rowIndex]?.index];
          return (
            <span
              onClick={
                user?.roleId === 2 ? () => handleSwap(customerData.email) : null
              }
              style={{
                cursor: "pointer",
              }}
            >
              {value}
            </span>
          );
        },
      },
    },
    { name: "email", label: "Email" },
    { name: "phone", label: "Phone" },
    {
      name: "is_priority",
      label: "Premium",
      options: {
        filter: false,
        customBodyRender: (value, tableMeta) => {
          const { currentTableData, rowIndex } = tableMeta;
          const data = tableMeta?.tableData[currentTableData[rowIndex]?.index];
          return (
            <Switch
              checked={value}
              onChange={(event) => handleSwitchChange(event, data)}
              color="primary"
            />
          );
        },
      },
    },
    {
      name: "is_active",
      label: "Status",
      options: {
        filter: false,
        customBodyRender: (value, tableMeta) => {
          const { currentTableData, rowIndex } = tableMeta;
          const data = tableMeta?.tableData[currentTableData[rowIndex]?.index];
          return (
            <Switch
              checked={value}
              onChange={(event) => handleStatus(data, event.target.checked)}
              color="primary"
            />
          );
        },
      },
    },
    {
      name: "id",
      label: "Actions",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => {
          const { currentTableData, rowIndex } = tableMeta;
          const customerData =
            tableMeta?.tableData[currentTableData[rowIndex]?.index];
          return (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <IconButton
                aria-label="edit"
                onClick={() =>
                  navigate(`/edit-customer/${customerData.customer_id}`)
                }
              >
                <EditIcon />
              </IconButton>
              {user?.roleId === 2 && (
                <IconButton
                  aria-label="edit"
                  onClick={() => handleSwap(customerData.email)}
                >
                  <SwapHorizIcon />
                </IconButton>
              )}
              <IconButton
                aria-label="edit"
                onClick={() =>
                  navigate(`/address-view/${customerData.customer_id}`)
                }
              >
                <VisibilityIcon />
              </IconButton>
              <Button
                className="add-address-btn"
                sx={{
                  position: "relative",
                }}
                size="small"
                variant="contained"
                onClick={() => {
                  handleAddressOpen(customerData.customer_id);
                }}
              >
                Add Address
              </Button>
            </Box>
          );
        },
      },
    },
  ];

  const fetchData = React.useCallback(async () => {
    try {
      const response = await axios.get("/customer");
      setRows(response?.data?.list);
    } catch (error) {
      fetchError("Unable to get customers");
    }
  }, [fetchError]);

  React.useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleSwitchChange = async (event, rowData) => {
    const newValue = event.target.checked;
    try {
      await axios.put(`/customer/is-priority-update/${rowData?.customer_id}`, {
        is_priority: newValue,
      });
      fetchSuccess("Premium updated successfully");
      fetchData();
    } catch (error) {
      fetchError(" Unable to updated premium");
    }
  };
  const handleStatus = async (rowData, isActive) => {
    try {
      await axios.put(`/customer/is-active/${rowData?.customer_id}`, {
        is_active: isActive,
      });
      fetchSuccess("Status updated successfully");
      fetchData();
    } catch (error) {
      fetchError(" Unable to updated status");
    }
  };

  return (
    <>
      <DashboardLayout>
        <DashboardNavbar />
        <Card
          style={{ padding: "24px", margin: "16px auto", maxWidth: "2000px" }}
        >
          <div
            style={{ display: "flex", justifyContent: "flex-end", gap: "16px" }}
          >
            <SoftButton variant="gradient" color="info" onClick={handleOpen}>
              Add Subscription
            </SoftButton>
            <SoftButton
              variant="gradient"
              color="info"
              onClick={() => navigate(`/add-customer`)}
            >
              Add Customer
            </SoftButton>
          </div>
          <SoftBox
            sx={{
              "& .MuiTableRow-root:not(:last-child)": {
                "& td": {
                  borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                    `${borderWidth[1]} solid ${borderColor}`,
                },
              },
            }}
          >
            <ThemeProvider theme={getMuiTheme}>
              <MUIDataTable
                className="manage-customers"
                title={"Manage Customers"}
                data={rows}
                columns={columns}
                options={options}
              />
            </ThemeProvider>
          </SoftBox>
        </Card>
      </DashboardLayout>
      {modalOpen && (
        <AddSubscriptionModal
          isOpen={modalOpen}
          onClose={handleClose}
          fetchData={fetchData}
        />
      )}
      {addressOpen && (
        <AddAddress
          isOpen={addressOpen}
          onClose={handleAddressClose}
          customerId={customerId}
          fetchData={fetchData}
          fetchSuccess={fetchSuccess}
          fetchError={fetchError}
        />
      )}
    </>
  );
};

export default CustomerList;
