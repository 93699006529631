import React from "react";
import { Link } from "react-router-dom";
import axios from "../../../api";
import { useSnackbar } from "components/AlertMessages/SnackbarContext";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { TextField, Button, Typography, Card } from "@mui/material";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import Footer from "../components/Footer";
import DefaultNavbar from "examples/Navbars/DefaultNavbar";

const ForgotPassword = () => {
  const { fetchError, fetchSuccess } = useSnackbar();

  const initialValues = {
    email: "",
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .matches(
        /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
        "Invalid email format"
      )
      .typeError("Enter a valid email")
      .required("Email is required"),
  });

  const handleSubmit = async (values, { setSubmitting, setFieldError }) => {
    try {
      const response = await axios.post("/auth/forgot-password", {
        email: values.email,
      });
      fetchSuccess(response.data.message);
    } catch (error) {
      fetchError(error.response.data.error);
    }
  };

  return (
    <>
      <DefaultNavbar />
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: "50px",
          marginBottom: "50px",
          width: "100%",
        }}
      >
        <Card
          className="signin-form signin-admin-form"
          sx={{
            maxWidth: "30vw",
            width: "100%",
            padding: "30px",
          }}
        >
          <Typography variant="h5" align="center" gutterBottom>
            Forgot Password
          </Typography>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({
              isSubmitting,
              touched,
              errors,
              handleChange,
              handleBlur,
              values,
            }) => (
              <Form>
                <SoftBox>
                  <SoftTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                  >
                    Email
                  </SoftTypography>
                  <TextField
                    fullWidth
                    type="email"
                    name="email"
                    variant="outlined"
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.email && Boolean(errors.email)}
                    helperText={touched.email && errors.email}
                  />
                </SoftBox>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={isSubmitting}
                  fullWidth
                  sx={{ mt: 2 }}
                >
                  Reset Password
                </Button>
              </Form>
            )}
          </Formik>
          <Typography variant="body2" align="center" sx={{ mt: 2 }}>
            Back to{" "}
            <Typography
              component={Link}
              to="/employee/sign-in"
              variant="body1"
              fontWeight="bold"
              sx={{ fontSize: "16px" }}
            >
              Sign In
            </Typography>
          </Typography>
        </Card>
      </div>
      <Footer />
    </>
  );
};

export default ForgotPassword;
