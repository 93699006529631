import PageLayout from "examples/LayoutContainers/PageLayout";
import Footer from "layouts/authentication/components/Footer";
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import { Box, Container, Grid, Typography } from "@mui/material";

const ReUsePage = ({ pageData }) => {
  return (
    <PageLayout>
      <DefaultNavbar />
      <Box component="section" className="about-us" py={8}>
        <Container>
          <Grid container item xs={12} lg={12} sx={{ mx: "auto" }}>
            <Grid item xs={12} md={6}>
              <Box textAlign="center" lineHeight={1}>
                <img
                  src={`${process.env.REACT_APP_CLOUD_FRONT_URL}/${pageData?.page_image}`}
                  alt="About Us"
                  height="300px"
                  style={{ width: "100%" }}
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box pl={4} pt={4} lineHeight={1}>
                <Typography mb={2} variant="h2" textGradient>
                  {pageData?.page_title}
                </Typography>
                <Typography variant="body2" color="text" mb={3}>
                  <div
                    dangerouslySetInnerHTML={{
                      __html:
                        pageData?.page_description || "No description found",
                    }}
                  />
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Footer />
    </PageLayout>
  );
};
export default ReUsePage;
