import * as React from "react";
import MUIDataTable from "mui-datatables";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Box, Card, IconButton, Tooltip } from "@mui/material";
import { Edit as EditIcon } from "@mui/icons-material";
import axios from "../../api";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import SoftBox from "components/SoftBox";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import SoftButton from "components/SoftButton";
import EditLift from "./EditLift";
import AddLift from "./AddLift";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useNavigate } from "react-router-dom";

const getMuiTheme = (theme) =>
  createTheme({
    components: {
      MUIDataTableHeadCell: {
        styleOverrides: {
          root: {
            backgroundColor: theme.palette.background.default,
          },
        },
      },
    },
  });

const options = {
  selectableRows: "none",
  selectableRowsHeader: false,
  elevation: 0,
};

const AdminLiftsList = () => {
  const navigate = useNavigate();
  const [rows, setRows] = React.useState([]);
  const [error, setError] = React.useState(null);
  const [isEditOpen, setEditOpen] = React.useState(false);
  const [isAddOpen, setAddOpen] = React.useState(false);
  const [liftData, setLiftData] = React.useState(null);

  const handleEditOpen = (data) => {
    setLiftData(data);
    setEditOpen(true);
  };

  const handleEditClose = () => {
    setEditOpen(false);
  };
  const handleAddOpen = () => {
    setAddOpen(true);
  };

  const handleAddClose = () => {
    setAddOpen(false);
  };
  const columns = [
    { name: "lift_service_id", label: "Service Id" },
    {
      name: "lift_name",
      label: "Lift Name",
      options: {
        filter: false,
        customBodyRender: (value, tableMeta) => {
          const { currentTableData, rowIndex } = tableMeta;
          const data = tableMeta?.tableData[currentTableData[rowIndex]?.index];
          const liftName = data?.lift_name;
          const displayValue =
            liftName?.length > 20
              ? `${liftName.substring(0, 20)}...`
              : liftName;
          return (
            <Tooltip title={liftName}>
              <span>{displayValue}</span>
            </Tooltip>
          );
        },
      },
    },
    { name: "customer_name", label: "Customer" },
    {
      name: "address",
      label: "Address",
      options: {
        filter: false,
        customBodyRender: (value, tableMeta) => {
          const { currentTableData, rowIndex } = tableMeta;
          const data = tableMeta?.tableData[currentTableData[rowIndex]?.index];
          const city = data?.city_name;
          const state = data?.state_name;
          const addressType = data?.address_type;
          const addressData = `${value}, ${city}, ${state},${addressType}`;

          const displayValue =
            addressData?.length > 20
              ? `${addressData.substring(0, 20)}...`
              : addressData;
          return (
            <Tooltip title={addressData}>
              <span>{displayValue}</span>
            </Tooltip>
          );
        },
      },
    },

    {
      name: "id",
      label: "Actions",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => {
          const { currentTableData, rowIndex } = tableMeta;
          const data = tableMeta?.tableData[currentTableData[rowIndex]?.index];
          return (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <IconButton
                aria-label="edit"
                onClick={() => handleEditOpen(data)}
              >
                <EditIcon />
              </IconButton>
              <IconButton
                aria-label="edit"
                onClick={() => navigate(`/admin/lifts-view/${data?.lift_id}`)}
              >
                <VisibilityIcon />
              </IconButton>
            </Box>
          );
        },
      },
    },
  ];

  const fetchData = React.useMemo(
    () => async () => {
      try {
        const response = await axios.get("/lifts");
        setRows(response?.data?.list);
      } catch (error) {
        setError(error);
      }
    },
    []
  );
  const [customers, setCustomers] = React.useState(null);
  const fetchCustomersData = React.useMemo(
    () => async () => {
      try {
        const customerResponse = await axios.get("/customer", {
          params: {
            is_active: 1,
          },
        });
        setCustomers(customerResponse?.data?.list);
      } catch (error) {
        setError(error);
      }
    },
    []
  );
  React.useEffect(() => {
    fetchData();
    fetchCustomersData();
  }, [fetchData, fetchCustomersData]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Card
        style={{ padding: "24px", margin: "16px auto", maxWidth: "2000px" }}
      >
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <SoftButton variant="gradient" color="info" onClick={handleAddOpen}>
            Add Lift
          </SoftButton>
        </div>
        <SoftBox
          sx={{
            "& .MuiTableRow-root:not(:last-child)": {
              "& td": {
                borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                  `${borderWidth[1]} solid ${borderColor}`,
              },
            },
          }}
        >
          <ThemeProvider theme={getMuiTheme}>
            <MUIDataTable
              className="manage-lifts"
              title={"Manage Lifts"}
              data={rows}
              columns={columns}
              options={options}
            />
          </ThemeProvider>
          {error && <div>Error: {error.message}</div>}
        </SoftBox>
      </Card>
      {isEditOpen && (
        <EditLift
          isOpen={isEditOpen}
          onClose={handleEditClose}
          liftData={liftData}
          fetchData={fetchData}
        />
      )}
      {isAddOpen && (
        <AddLift
          isOpen={isAddOpen}
          onClose={handleAddClose}
          fetchData={fetchData}
          customers={customers}
        />
      )}
    </DashboardLayout>
  );
};

export default AdminLiftsList;
