import { Box, Typography } from "@mui/material";

const FormatConversationHistory = (conversationData, user) => {
  const data = JSON?.parse(conversationData);
  const role = user?.Role[0] || "customer";
  return (
    data?.map((conversation, index) => {
      // Directly use the conversation object if it is a message
      const message = conversation.message || conversation;

      if (!message || !message.id) {
        console.error("Invalid message data", message);
        return null; // Skip rendering this item
      }

      return (
        <Box key={message.id} mb={2} className="chat-box">
          <Box
            display="flex"
            flexDirection={message.sender_role === role ? "row-reverse" : "row"}
          >
            <Box
              sx={{
                maxWidth: "70%",
                padding: "10px",
                borderRadius: "8px",
                backgroundColor:
                  message.sender_role === role ? "#e3f2fd" : "#fff",
                border: "1px solid #ddd",
              }}
            >
              <Typography variant="subtitle2" color="textSecondary">
                <span style={{ color: "black" }}>
                  {message.sender_name || "Unknown"}
                </span>
              </Typography>
              <Typography variant="body1">
                {message.text || "No message"}
              </Typography>
              <Typography
                variant="subtitle2"
                color="textSecondary"
                sx={{ display: "flex", justifyContent: "right" }}
              >
                <span>{message.date || "Unknown date"}</span>
              </Typography>
            </Box>
          </Box>
        </Box>
      );
    }) || <Typography>No conversations available</Typography>
  );
};

export default FormatConversationHistory;
