const DateFormat = (date) => {
  if (!date) {
    date = new Date();
  }
  const options = {
    year: "numeric",
    month: "short",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
  };
  return date.toLocaleString("en-US", options);
};
export default DateFormat;
