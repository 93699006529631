import * as React from "react";
import MUIDataTable from "mui-datatables";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Button, Card, Checkbox, Switch } from "@mui/material";
import axios from "../../api";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import SoftBox from "components/SoftBox";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import AddCategories from "./AddCategories";
import PageLoader from "examples/ReuseFunctions/PageLoader";
import { useSnackbar } from "components/AlertMessages/SnackbarContext";
import SoftButton from "components/SoftButton";

const getMuiTheme = (theme) =>
  createTheme({
    components: {
      MUIDataTable: {
        styleOverrides: {
          responsiveScroll: {
            maxHeight: "unset",
            overflowX: "auto",
          },
          responsiveScrollFullHeight: {
            maxHeight: "unset",
            overflowX: "auto",
          },
          responsiveScrollMaxHeight: {
            maxHeight: "unset",
            overflowX: "auto",
          },
        },
      },
      MUIDataTableHeadCell: {
        styleOverrides: {
          root: {
            backgroundColor: theme.palette.background.default,
          },
        },
      },
    },
  });

const options = {
  selectableRows: "none",
  selectableRowsHeader: false,
  elevation: 0,
};

const CategoriesList = () => {
  const [categoryData, setCategoryData] = React.useState(null);
  const [isOpen, setOpen] = React.useState(false);
  const [selectedRows, setSelectedRows] = React.useState([]);
  const { fetchError, fetchSuccess } = useSnackbar();
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleStatus = async (data, isActive) => {
    try {
      const response = await axios.put(`/category/${data?.category_id}`, {
        is_active: isActive,
      });
      fetchData();
      fetchSuccess(response.data.message);
    } catch (error) {
      fetchError(error.response.data.error);
    }
  };
  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "short", day: "numeric" };
    const date = new Date(dateString);
    return new Intl.DateTimeFormat("en-US", options).format(date);
  };

  const fetchData = async () => {
    try {
      const response = await axios.get("/category");
      setCategoryData(response?.data?.list);
    } catch (error) {
      console.error("Error: ", error);
    }
  };

  React.useEffect(() => {
    fetchData();
  }, []);

  const handleBulkStatusUpdate = async (isActive) => {
    try {
      const promises = selectedRows.map(async (row) => {
        const category = categoryData[row.dataIndex];
        return axios.put(`/category/${category.category_id}`, {
          is_active: isActive,
        });
      });
      await Promise.all(promises);
      setSelectedRows([]);
      fetchData();
      fetchSuccess(
        isActive ? "Categories activated" : "Categories deactivated"
      );
    } catch (error) {
      fetchError(error.response.data.error);
    }
  };
  const handleCheckAllboxes = (checked) => {
    // If 'checked' is true, select all rows, otherwise, deselect all rows
    setSelectedRows(
      checked ? categoryData.map((_, index) => ({ dataIndex: index })) : []
    );
  };

  const handleCheckboxChange = (rowIndex) => {
    setSelectedRows((prevSelectedRows) => {
      const isSelected = prevSelectedRows.some(
        (row) => row.dataIndex === rowIndex
      );
      return isSelected
        ? prevSelectedRows.filter((row) => row.dataIndex !== rowIndex)
        : [...prevSelectedRows, { dataIndex: rowIndex }];
    });
  };

  if (!categoryData) {
    return <PageLoader />;
  }
  const columns = [
    {
      name: "checkbox",
      label: "",
      options: {
        filter: false,
        sort: false,
        customHeadRender: (columnMeta) => (
          <th
            style={{
              padding: "16px",
              textAlign: "left",
              backgroundColor: "#f8f9fa",
              borderBottom: "1px solid #e0e0e0",
            }}
          >
            <Checkbox
              checked={
                categoryData.length > 0 &&
                selectedRows.length === categoryData.length
              }
              onChange={(event) => handleCheckAllboxes(event.target.checked)}
            />
          </th>
        ),
        customBodyRender: (value, tableMeta) => (
          <Checkbox
            checked={selectedRows.some(
              (row) => row.dataIndex === tableMeta.rowIndex
            )}
            onChange={() => handleCheckboxChange(tableMeta.rowIndex)}
          />
        ),
      },
    },
    { name: "name", label: "Category Name" },
    {
      name: "created_at",
      label: "Created At",
      options: {
        filter: false,
        customBodyRender: (value) => formatDate(value),
      },
    },
    {
      name: "is_active",
      label: "Status",
      options: {
        filter: false,
        customBodyRender: (value, tableMeta) => {
          const { currentTableData, rowIndex } = tableMeta;
          const data = tableMeta?.tableData[currentTableData[rowIndex]?.index];
          return (
            <Switch
              checked={value}
              onChange={(event) => handleStatus(data, event.target.checked)}
              color="primary"
            />
          );
        },
      },
    },
  ];

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Card
        style={{ padding: "24px", margin: "16px auto", maxWidth: "2000px" }}
      >
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <SoftButton
            className="success-btn"
            variant="gradient"
            color="success"
            onClick={() => {
              handleOpen();
            }}
          >
            Add Category
          </SoftButton>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              variant="contained"
              className="success-btn"
              onClick={() => {
                handleBulkStatusUpdate(true);
              }}
              disabled={selectedRows.length === 0}
              sx={{ marginRight: "10px" }}
            >
              Activate
            </Button>
            <Button
              variant="contained"
              className="success-btn"
              onClick={() => {
                handleBulkStatusUpdate(false);
              }}
              disabled={selectedRows.length === 0}
            >
              InActivate
            </Button>
          </div>
        </div>
        <SoftBox
          sx={{
            "& .MuiTableRow-root:not(:last-child)": {
              "& td": {
                borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                  `${borderWidth[1]} solid ${borderColor}`,
              },
            },
          }}
        >
          <ThemeProvider theme={getMuiTheme}>
            <MUIDataTable
              className="manage-tickets m-category"
              title={"Manage Categories"}
              data={categoryData}
              columns={columns}
              options={options}
            />
          </ThemeProvider>
        </SoftBox>
      </Card>
      {isOpen && (
        <AddCategories
          isOpen={isOpen}
          onClose={handleClose}
          fetchData={fetchData}
        />
      )}
    </DashboardLayout>
  );
};

export default CategoriesList;
