import DateFormat from "examples/ReuseFunctions/DateFormat";

const StatusHistory = (previousData, createdby, statusName) => {
  const currentTime = DateFormat();
  const newEntry = {
    Status: statusName,
    "Changed by": createdby,
    Date: currentTime,
  };

  // Flatten the previous data
  const flattenData = (data) => {
    return data.reduce((acc, item) => {
      if (Array.isArray(item)) {
        return acc.concat(flattenData(item));
      } else {
        acc.push(item);
        return acc;
      }
    }, []);
  };

  const historyDataArray = previousData
    ? flattenData(JSON.parse(previousData))
    : [];
  historyDataArray.push(newEntry);

  return JSON.stringify(historyDataArray);
};

export default StatusHistory;
